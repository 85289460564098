import React, {useContext, useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    message,
    Row,
    Select,
    Space,
    Radio,
    Card,
    Alert
} from "antd";
import logo_mass from "../../assets/logo-mass.png";
import logo_udc from "../../assets/logo-udc.png";
import flag from "../../assets/flag.jpg";
import SearchableSelect from "../../components/SearchableSelect";
import {FacebookOutlined, InstagramOutlined, SendOutlined, TwitterOutlined} from "@ant-design/icons";
import {API} from "../../tools/api";
import {useMutation} from "react-query";
import {error, success} from "../../components/Alerts";
import {
    BnfApplication, Constituency,
    MassBnfApplication, MembershipClass,
    SelectOption,
} from "../../tools/types";
import {constituencies, occupations, institutions, membershipTypes, NA} from '../../tools/constants';
import {AppContext} from "../../App";
import {createSelectObjectsFromArray, upperCaseFirstLetter} from "../../tools/functions";
import ConstituencyWardSelectors from "../../components/ConstituencyWardSelectors";
import Title from "antd/es/typography/Title";

const { Option } = Select;

const ApplyBnf = () => {
    const {api} = useContext(AppContext);
    const [form] = Form.useForm();
    const {isSuccess, isError, error,isLoading, mutate: addApplicationM, reset} = useMutation('addBnfApplication', api.addBnfApplication, {retry: 2});
    // store fname and sname as they are being typed
    const[fname, setFname] = useState<string>("");
    const[sname, setSname] = useState<string>("");
    // selected constituency
    const [selConstituency, setSelConstituency] = useState<SelectOption>();
    // membership class selected -- default to regular
    const [membershipClass, setMembershipClass] = useState<MembershipClass>('regular');


    // run once when success changes
    useEffect(()=>{
        if (isSuccess) message.success("Application Successfully Submitted.").then(()=> {
            // clear the form
            form.resetFields();
            // reset the query
            reset();
        });
        if (isError) message.error('UnSuccessful! Please retry.').then(()=> api.logError(error));
        if (isLoading)  message.info('Loading...');

    }, [isSuccess, isError, isLoading])

    // method to register from form details
    const register = (v: any) => {

        // fill in details
        let application: BnfApplication = {
            birthPlace: v.birthPlace,
            membershipClass: v.membershipClass || 'regular',
            constituency: v.constituency,
            membershipType: v.membershipType,
            // number set in the backend
            membershipNumber: 0,
            occupation: v.occupation  || NA,
            postalAddress: v.postalAddress,
            prevParty: v.prevParty || NA,
            profession: v.profession  || NA,
            ward: v.ward,
            evalStage: 'ward',
            evaluations: [],
            appliedOn: new Date(),
            dob: v['dob'].toDate(),
            docId: "",
            email: v.email || NA,
            fname: v.fname,
            gender: v.gender,
            omang: v.omang,
            phone: "+267" + v.phone.trim(),
            sname: v.sname,
            status: 'pending'
        }

        // route to payment for VIP for vip
        if (membershipClass === 'vip'){
            // check success of payment
            processVIPPayment().then(success => {
                // successful payment
                if (success){
                    // add to database
                    addApplicationM(application);
                }
                // unsuccessful payment
                else{
                    message.error('Unable to process payment. Please retry.');
                }
            })

        }
        else{
            // add to database
            addApplicationM(application);
        }
    }

    const processVIPPayment = async () : Promise<Boolean> => {
        return true;
    }

    return (
        <div className={'register'}>
            <Row gutter={[4, 16]} justify="space-between" align={'middle'}>

                {/*logo -- show on !mobile screens */}
                <Col xs={24} sm={6} md={6} lg={6}>
                    <img
                        src={flag}
                        className={'img-logo'}
                    />
                </Col>

                {/*Form Heading -- show on all screens*/}
                <Col xs={24} sm={16} md={12} lg={12}>
                    <div className={'main-heading'}>
                        <h1> BOTSWANA NATIONAL FRONT </h1>
                        <h3> MEMBERSHIP APPLICATION FORM</h3>
                    </div>
                </Col>

                {/*logo !mobile screens*/}
                <Col xs={0} sm={0} md={6} lg={6}>
                    <img
                        src={flag}
                        className={'img-logo'}
                    />
                </Col>

                {/*Form details*/}
                <Col span={24} className={'form-wrapper'}>
                    <img
                        className="form-bg"
                        src={flag}
                        alt="flag"
                    />

                    <Form
                        form={form}
                        onFinish={register}
                        layout={'vertical'}
                        className={'form-content'}
                    >

                        <Divider orientation="left" className={'divider'}><strong>PERSONAL DETAILS</strong> </Divider>

                        <Row gutter={[8,16]}>

                            {/*Surname*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'sname'} label={'Surname'} rules={[{required: true, message: 'Please enter your Surname.'}]}>
                                    <Input placeholder={'Surname'} type={'text'} onChange={(e)=>setSname(e.target.value)} />
                                </Form.Item>
                            </Col>

                            {/*FirstName*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'fname'} label={'First Name(s)'} rules={[{required: true, message: 'Please enter your First Name(s).'}]}>
                                    <Input placeholder={'First Name(s)'} type={'text'} onChange={(e)=>setFname(e.target.value)}/>
                                </Form.Item>
                            </Col>

                            {/*DOB*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'dob'} label={'Date of Birth (YYYY-MM-DD)'} rules={[{required: true, message: 'Please select your Date of Birth.'}]}>
                                    <DatePicker style={{width: '100%'}} />
                                </Form.Item>
                            </Col>

                            {/*birth place*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'birthPlace'} label={'Birth Place'} rules={[{required: true, message: 'Please enter your Place of Birth.'}]}>
                                    <Input placeholder={'Lobatse Clinic'} type={'text'}/>
                                </Form.Item>
                            </Col>

                            {/*Gender*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'gender'} label={'Gender'} rules={[{required: true, message: 'Please select your Gender.'}]}>
                                    <Select placeholder="Select Gender">
                                        <Option value="F">Female</Option>
                                        <Option value="M">Male</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            {/*Omang*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'omang'} label={'Omang / Passport Number'} rules={[{required: true, message: 'Please enter your Omang / Passport Number.'}]}>
                                    <Input placeholder={'012345678'} type={'text'}/>
                                </Form.Item>
                            </Col>

                            {/*phone*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'phone'} label={'Mobile Phone Number'} rules={[{required: true, message: 'Please enter your Mobile Phone Number.'}]}>
                                    <Input addonBefore="+267" placeholder={'74123456'} type={'number'}/>
                                </Form.Item>
                            </Col>

                            {/*email*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'email'} label={'Email Address'}
                                           rules={[
                                               {type: 'email', message: 'The input is not a valid email address.'},
                                               // {required: true, message: 'Please enter your email address.'}
                                            ]
                                           }
                                >
                                    <Input placeholder={'abc@gmail.com'} type={'email'}/>
                                </Form.Item>
                            </Col>

                            {/*occupation*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'occupation'} label={'Occupation'}
                                           // rules={[{required: true, message: 'Please select your Occupation..'}]}
                                >
                                    <Select placeholder="Select Occupation">
                                        {occupations.sort().map(occ =>
                                            <Option value={occ} key={occ}>{upperCaseFirstLetter(occ)}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>

                            {/*profession*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'profession'} label={'Profession'}
                                           // rules={[{required: true, message: 'Please enter your Profession.'}]}
                                >
                                    <Input placeholder={'Primary School Teacher'} type={'text'}/>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Divider orientation="left" className={'divider'}><strong>APPLICATION DETAILS</strong> </Divider>
                            </Col>

                            <ConstituencyWardSelectors />

                            {/*postalAddress*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'postalAddress'} label={'Postal Address'} rules={[{required: true, message: 'Please enter your postal address.'}]}>
                                    <Input placeholder={'PO Box 123 Gaborone North'} type={'text'}/>
                                </Form.Item>
                            </Col>

                            {/*membership types*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'membershipType'} label={'Membership Type'} rules={[{required: true, message: 'Please select your Membership Type.'}]}>
                                    <Select placeholder="Select Membership Type">
                                        {membershipTypes.map(mt =>
                                            <Option value={mt} key={mt}> {upperCaseFirstLetter(mt)} </Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>

                            {/*prev party*/}
                            <Col span={24}>
                                <Form.Item name={'prevParty'} label={'Name of Previous Political Party'}
                                           // rules={[{required: true, message: 'Please enter your previous political party or N/A.'}]}
                                >
                                    <Input placeholder={'Party 123'} type={'text'}/>
                                </Form.Item>
                            </Col>

                            {/*membership class -- VIP or regular*/}
                            <Col span={24}>
                                <Form.Item name={'membershipClass'} label={'Membership Class'} >

                                    <Radio.Group defaultValue="regular" size="small" style={{ marginTop: 16 }} buttonStyle={"solid"} onChange={(e) => setMembershipClass(e.target.value)} >

                                        <Row gutter={[12,12]} justify={'center'} align={'middle'}>
                                            <Col xs={24} sm={12} md={12} lg={12} >

                                                    <Radio.Button  value="regular" style={{maxWidth: '300px', height: '130px', borderRadius: '5%', borderColor: '#0A8A4C'}}>
                                                        <strong>
                                                            <Title level={4} style={{color: '#0A8A4C'}} >Regular Membership</Title>
                                                            <ul>
                                                                <li>Membership number above 2,001</li>
                                                                <li>No cost</li>
                                                            </ul>
                                                        </strong>
                                                    </Radio.Button>
                                            </Col>

                                            <Col xs={24} sm={12} md={12} lg={12} >

                                                <Radio.Button  value="vip" style={{maxWidth: '300px', height: '130px', borderRadius: '5%', borderColor: '#0A8A4C'}}>
                                                    <strong>
                                                        <Title level={4} style={{color: '#0A8A4C'}}>VIP Membership</Title>
                                                        <ul>
                                                            <li>Special membership number between 2 and 2,000</li>
                                                            <li> Costs BWP1,000.00</li>
                                                        </ul>
                                                    </strong>
                                                </Radio.Button>
                                            </Col>
                                        </Row>
                                    </Radio.Group>

                                </Form.Item>
                            </Col>

                            {membershipClass === 'vip' &&
                                <Col  span={24} >
                                    <Alert
                                        message={<u>Banking Details</u>}
                                        description={
                                            <p>
                                                <strong>Account Holder</strong>: Botswana National Front
                                                <br/>
                                                <strong>Bank</strong>: First National Bank (FNB), Mall Branch
                                                <br/>
                                                <strong>Account Number</strong>: 62057682637
                                                <br/>
                                                <strong>Reference</strong>: {sname.toUpperCase()} {fname.toUpperCase()}
                                                <br/>
                                                <br/>
                                                <strong>To receive your membership number, kindly email your proof of payment of BWP1,000.00 to treasurer@votebnf.com</strong>
                                            </p>
                                        }
                                        type={"info"}
                                        showIcon
                                        style={{maxWidth:'600px'}}
                                    />
                                </Col>
                            }


                        </Row>

                        <Divider orientation="left" className={'divider'}><strong>MEMBERSHIP OATH DECLARATION</strong></Divider>

                        <p><strong>
                            I, <i className={'name-text'}><u>{sname.toUpperCase()} {fname.toUpperCase()}</u></i>,
                            PLEDGE AS A MEMBER OF THE BOTSWANA NATIONAL FRONT THAT I SHALL AT ALL TIMES BE LOYAL TO THE FRONT AND THAT I SHALL WORK TIRELESSLY FOR THE UNITY, LIBERATION AND GENUINE INDEPENDENCE OF BOTSWANA.
                            <br/>
                        </strong></p>

                        {/*Agreement to the oath*/}
                        <Form.Item name={'oath'} rules={[{required: true, message: 'Please tick here if you agree to the oath above.'}]}>
                            <Checkbox.Group>
                                <Checkbox value={true} className={'checkbox'}> <strong>I AGREE </strong> </Checkbox>
                            </Checkbox.Group>
                        </Form.Item>

                        {/*submit button*/}
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className={'btn-submit'} loading={isLoading}>
                                <Space size={'middle'}>
                                    <strong>SUBMIT APPLICATION</strong>
                                    <SendOutlined />
                                </Space>
                            </Button>
                        </Form.Item>

                    </Form>

                </Col>

                {/*Social media icons*/}
                <Col span={24}>
                    <Space size={'middle'} align="start" style={{marginTop: '10px', marginBottom: '0px'}}>
                        <InstagramOutlined />
                        <FacebookOutlined />
                        <TwitterOutlined />
                        <p><strong>BNF</strong></p>
                    </Space>
                </Col>

                {/*bottom design*/}
                <Col span={18}><div className={'bottom left'}></div></Col>
                <Col span={6}><div className={'bottom right'}></div></Col>

            </Row>
        </div>

    );
};

export default ApplyBnf;