import React, {useContext, useEffect, useState} from "react";
import {useMutation} from "react-query";
import {AppContext} from "../../App";
import {Button, Col, Empty, Form, Input, message, Radio, Row, Select, Space, Typography} from "antd";
import ErrorBoundary from "../../components/ErrorBoundary";
import {SearchOutlined} from "@ant-design/icons";
import CustomCountUp from "../../components/CustomCountUp";
import UserCard from "../../components/users/UserCard";
import ConstituencyWardSelectors from "../../components/ConstituencyWardSelectors";
import {evaluatorTypes, userRoles} from "../../tools/constants";
import {Filter} from "../../tools/types";

const {Option} = Select;
const {Title} = Typography;


const SearchUsers = () => {
    const {api} = useContext(AppContext);
    const [form] = Form.useForm();
    // currently selected role
    const [role, setRole] = useState<string>();

    // getting user data
    const {
        data,
        isError,
        isLoading,
        isSuccess,
        mutate, error
    } = useMutation('getUsers', api.getUsers, {retry: 2});

    // for logging an error message
    useEffect(()=> {
        if (isError) message.error('An error occurred. Please try again.').then(()=> api.logError(error))
    } , [isError])

    const search = (values: any) => {
        let filters: Filter[] = [];

        // fix fields if supplied
        if (values.omang?.trim()) filters.push({field: 'omang', value: values.omang});
        if (values.role) filters.push({field: 'role', value: values.role});
        if (values.evaluatorType) filters.push({field: 'evaluatorType', value: values.evaluatorType});
        if (values.constituency) filters.push({field: 'constituency', value: values.constituency});
        // for ward, constituency must also have a value, otherwise it is disregarded
        if (values.constituency && values.ward) filters.push({field: 'ward', value: values.ward});

        mutate(filters);
    }


    return (
        <ErrorBoundary>
                <Space direction={'vertical'} style={{width: '100%'}}>
                    <Title level={3} style={{textAlign: 'center' as const}}>Search System Users</Title>


                    {/*Search fields form*/}
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={search}
                    >
                        <Row gutter={[16,16]}>
                            {/*User's Omang*/}
                            <Col span={24}>
                                <Form.Item
                                    name="omang"
                                    label="Omang / Passport Number:"
                                    className={'form-item'}
                                    rules={[{ required: false, message: 'Please input the Omang/Passport number!' }]}
                                >
                                    <Input placeholder="1234567" />
                                </Form.Item>
                            </Col>

                            {/*User's Role*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item
                                    name="role"
                                    label="User Role"
                                    // className={'form-item'}
                                    rules={[{ required: false, message: 'Please choose user role.' }]}
                                >
                                    <Select allowClear onSelect={(role) => setRole(role as string)} placeholder={'Select User Role'}>
                                        {userRoles.map(role =>
                                            <Option key={role} value={role}>{role.toUpperCase()}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>

                            {/*Evaluator Type*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item
                                    name="evaluatorType"
                                    label="Evaluating For"
                                    // className={'form-item'}
                                    rules={[{ required: false, message: 'Please choose user which party the user is evaluating for' }]}
                                >
                                    <Select allowClear disabled={role==='admin'} placeholder={'Select Party Evaluating For'}>
                                            {evaluatorTypes.filter(ev => ev !== 'n/a').map(ev =>
                                                <Option key={ev} value={ev}>{ev.toUpperCase()}</Option>
                                            )}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <ConstituencyWardSelectors required={false}/>
                        </Row>


                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{width: '100%', height: '40px', color: '#0A8A4C'}}
                                size={'large'}
                                loading={isLoading}
                                icon={<SearchOutlined/>}
                            >
                                SEARCH USERS
                            </Button>
                        </Form.Item>

                    </Form>

                    {/*when search is successful*/}
                    {isSuccess && data &&
                    // Show number of results
                        <div>
                            <CustomCountUp end={data.length}/>

                            {/*Results*/}
                            <Row gutter={[24, 24]}>
                                {
                                    data.map((user: any) =>
                                        <Col xs={24} sm={24} md={12} lg={12} key={user.docId}>
                                            <UserCard user={user}/>
                                        </Col>
                                    )
                                }
                            </Row>
                        </div>
                    }

                    {/*if no data is found*/}
                    {isSuccess && data?.length === 0 &&
                    <Empty description={'No Results Found.'}/>
                        // <EmptySVG style={{height: '250px'}}/>
                    }

                </Space>
        </ErrorBoundary>

    )
}

export default SearchUsers;