import React, {useContext, useEffect, useState} from 'react';
import {User} from "../../tools/types";
import {useMutation} from "react-query";
import {API} from "../../tools/api";
import {Form, message, Row, Typography} from 'antd';
import {UserForm} from "../../components/users/users";
import {AppContext} from "../../App";

const {Title} = Typography;

const AddUser = () => {
    const [form] = Form.useForm();
    const {api} = useContext(AppContext);
    // mutation to add clint to the db
    const {isLoading, isSuccess, isError, mutate, error, reset} = useMutation('addUser', api.addUser, {retry: 2});

    // controls state messages
    useEffect(() => {
        if (isSuccess) message.success('Successfully added.').then(()=> {
            // clear the form
            form.resetFields();
            // reset the query
            reset();
        });
        if (isError) message.error('An error occurred. Please try again.').then(()=> api.logError(error));
    }, [isSuccess, isError])

    // function to add user object to the database
    const addUser = (values: any) =>{
        const user: User = {...values, docId:'', deleted: false, email: values.email.trim(), phone: '+267' + values.phone.trim()};
        mutate(user);
    }

    // message props
    message.config({
        top: 100,
        duration: 3
    });

    return (
        <div style={{textAlign: 'center' as const}}>
            <Title level={3}>Add System User</Title>

            <UserForm submitForm={addUser} isLoading={isLoading} form={form}/>
        </div>
        )

}

export default AddUser;