import React from 'react';
import {Button, Form, Input} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {PropsForgotPasswordForm} from "../../tools/types";
import '../../styles/login.less';

const ForgotPasswordForm: React.FC<PropsForgotPasswordForm> = ({handleForgot, setLogin}) => {
    return (
        <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={(e)=>handleForgot(e)}
        >

            {/*<h1 className={'form-item'}> <strong>Forgot Password</strong></h1>*/}
            <p className={'form-item'}>
                <i>Enter your email below to receive a password reset link.</i>
            </p>

            <Form.Item
                name="email"
                className={'form-item'}
                rules={[{ required: true, message: 'Please input your Email Address!' }]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email Address" />
            </Form.Item>


            <Form.Item>
                <Button type="primary" htmlType="submit" className={'btn-login'}>
                    <strong>RESET PASSWORD</strong>
                </Button>
            </Form.Item>

            <Form.Item>
                <a href="#" onClick={() => setLogin(true)}>
                    <h3 className={'form-item'}>
                        <strong>Back To Login</strong>
                    </h3>
                </a>
            </Form.Item>
        </Form>
    )
}

export default ForgotPasswordForm;