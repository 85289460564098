import React, {useState, useEffect, useContext} from 'react';
import {
    Application,
    BnfApplication,
    EvaluatorType,
    MassBnfApplication,
    QuerySnapshot,
    UserRole,
    CollectionReference,
    ApplicationStatus, User, EvaluationStage, FQuery
} from '../tools/types';
import {blankUser} from '../tools/constants';
import {AppContext} from '../App';
import {queryLimit} from '../tools/constants';
import {Button, Card, Col, Input, message, Popconfirm, Row, Space, Typography} from "antd";
import ApplicationsGrid from "../components/ApplicationsGrid";
import {db} from "../tools/firebase";
import firebase from "firebase";

const {TextArea} = Input;
const {Title} = Typography;


const Evaluate = () => {
    const {user, api} = useContext(AppContext);
    const [applications, setApplications] = useState<BnfApplication[] | MassBnfApplication[]>([]);
    const [isError, setIsError] = useState<boolean>(false);

    // controls realtime query
    useEffect(() => {

        let bnfApplicationsObserver: () => any = () => {
        };
        let massBnfApplicationsObserver: () => any = () => {
        };

        // for evaluators
        if (user?.role === 'evaluator') {
            // for national bnf
            if (user?.evaluatorType === 'bnf') {
                bnfApplicationsObserver = addQueryParameters(addBnfFilters(api.bnfApplicationsRef)).onSnapshot((snap: QuerySnapshot) => {
                    updateApplications(snap);
                }, (err) => handleError(err));

                // api.bnfApplicationsRef.where('constituency', '==', user.constituency).limit(queryLimit).onSnapshot(snap => {})
            }
            // for uni
            else {
                massBnfApplicationsObserver = addQueryParameters(api.massBnfApplicationsRef).onSnapshot((snap: QuerySnapshot) => {
                    updateApplications(snap);
                }, (err) => handleError(err));
            }
        }
        // for admins use national bnf with no constituency
        else if (user?.role === 'admin') {
            bnfApplicationsObserver = addQueryParameters(api.bnfApplicationsRef).onSnapshot((snap: QuerySnapshot) => {
                updateApplications(snap);
            }, (err) => handleError(err));
        }

        // detach observers when done
        return () => {
            bnfApplicationsObserver();
            massBnfApplicationsObserver();
        }
    }, [])

    // control error message
    useEffect(() => {
        if (isError) message.error('An error occurred, please reload the page.');
    }, [isError])

    // function to update applications array from snap
    const updateApplications = (snap: QuerySnapshot) => {
        let docs: BnfApplication[] | MassBnfApplication[] = [];
        // loop through docs
        snap.docs.forEach(doc => {
            const data = doc.data();
            // @ts-ignore -- fields are overwritten
            docs.push({
                ...data,
                dob: data.dob.toDate(),
                appliedOn: data.appliedOn.toDate(),
                evaluations: api.processEvaluations(data.evaluations)
            })
        });

        // update state
        setApplications(docs);
    }

    // add status pending and order and limit
    const addQueryParameters = (ref: FQuery) => {
        return ref.where('status', '==', 'pending').orderBy('appliedOn', 'asc').limit(queryLimit);
        // return ref.where('status', '==', 'pending').orderBy('fname').limit(queryLimit);
    }

    // apply evaluation stage and constituency/ward for bnf application
    const addBnfFilters = (bnfRef: CollectionReference) : FQuery => {
        // get the evaluation stage
        const evalStage: EvaluationStage = user? user.evalStage : 'ward';

        // add evaluation stage
        let query = bnfRef.where('evalStage', '==', evalStage);

        // for secgen, return as is
        if (evalStage === 'secgen'){
            return query;
        }

        // for ward and constituency, add filters
        query = query.where('constituency', '==', user?.constituency);

        // return value
        if(evalStage == 'constituency'){
            // return as is
            return query;
        }
        else if(evalStage == 'ward'){
            // for ward, add ward filter
            return query.where('ward', '==', user?.ward);
        }
        else{
            // in other cases, return the query
            return query;
        }

    }

    // run when an error occurs while listening to doc with list
    const handleError = (err: any) => {
        api.logError(err);
        setIsError(true);
    }

    // create a title depending on the user
    let title: string = '';
    let subtitle: string = '';
    if (user?.role === 'admin') title = 'bnf';
    else if (user?.role === 'evaluator') title = user?.evaluatorType;


    return (
        <div style={{textAlign: 'center' as const}}>
            <Title level={3} style={{textAlign: 'center' as const}}>{title.toUpperCase()} APPLICATIONS</Title>

            {/*for evaluators of bnf, show their constituency, ward, and evaluation stage*/}
            {user?.evaluatorType === 'bnf' &&
                <div style={{lineHeight: '10px'}}>
                    {user?.evalStage==='ward' && <Title level={5}>{user?.constituency.toUpperCase()}, {user?.ward}</Title>}
                    {user?.evalStage==='constituency' && <Title level={5}>{user?.constituency.toUpperCase()}</Title>}
                    <p><strong>Evaluation Stage: {user?.evalStage.toUpperCase()}</strong></p>
                </div>

            }

            <ApplicationsGrid applications={applications}/>
        </div>
    );
};

export default Evaluate;