import firebase from "firebase";

// production
const config = {
    apiKey: "AIzaSyDSRZUcikDrN8_oV9dsOVlLCulG0tEXFr4",
    authDomain: "bnf-membership.firebaseapp.com",
    projectId: "bnf-membership",
    storageBucket: "bnf-membership.appspot.com",
    messagingSenderId: "732862759575",
    appId: "1:732862759575:web:234c7a40c47290d500dfd2",
    measurementId: "G-4TCRMGRWPT"
};

// development
// const config = {
//     apiKey: "AIzaSyBslymKw6Eimw_dKyIzMRZnawkxwJdj68E",
//     authDomain: "mass-bnf-membership.firebaseapp.com",
//     projectId: "mass-bnf-membership",
//     storageBucket: "mass-bnf-membership.appspot.com",
//     messagingSenderId: "82533298682",
//     appId: "1:82533298682:web:cf94f1ccf4fef10304b443",
//     measurementId: "G-XMTTGF5K7G"
// };

// for admin functions such as auth
const admin = firebase.initializeApp(config);
// for database interactions
const db = admin.firestore();

// for adding to an array -- adds an object
const addToArray = (object: object) => {
    return firebase.firestore.FieldValue.arrayUnion(object);
}

export { db, admin, addToArray };



