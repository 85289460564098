import React, { useEffect, useState, useContext } from "react";
import {admin } from "../tools/firebase";
import {Spin} from "antd";
import {AppContext} from "../App";
import {blankUser} from "../tools/constants";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(null);
	const [pending, setPending] = useState(true);

	useEffect(() => {
		admin.auth().onAuthStateChanged((user) => {
			setCurrentUser(user);
			setPending(false);
		});
	}, []);

	if (pending) {
		return(
			<div style={{textAlign: 'center', width: '100%', marginTop: '300px'}}>
				<Spin size="large" />
				<h3><i>Loading Details ...</i></h3>
			</div>
		)
	}

	return (
		<AuthContext.Provider
			value={{
				currentUser: currentUser,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};