import {
    ApplicationStatus,
    Constituency,
    EvaluatorType,
    MassBnfApplication,
    MembershipType,
    Occupation,
    SelectOption, Institution,
    User,
    UserRole, EvaluationStage
} from "./types"

// ----------- DATA -----------------

// limit on the number of items returned by an onSnapshot for applications
export const queryLimit: number = 2;

// occupations
export const occupations: Occupation[] = ['student' , 'self-employed' , 'unemployed' , 'employed' , 'retired'];

// types of bnf members
export const membershipTypes: MembershipType[] = ['life' , 'annual'];

// user roles
export const userRoles: UserRole[] = ['admin', 'evaluator'];

// types of evaluators
export const evaluatorTypes: EvaluatorType[] = ['bnf', 'mass-bnf', 'n/a'];

// stages of evaluation
export const evaluationStages: EvaluationStage[] = ['ward', 'constituency', 'secgen', 'n/a'];

// statuses of an application
export const applicationStatuses: ApplicationStatus[] = ['pending', 'approved', 'denied'];

// not applicable
export const NA = 'n/a';

// ----------- BLANKS -----------------

// blank mass-bnf application
export const blankMassBnfApplication: MassBnfApplication = {
    appliedOn: new Date(),
    school: "",
    dob: new Date(),
    docId: "",
    email: "",
    facebook: "",
    fname: "",
    gender: 'F',
    instagram: "",
    omang: "",
    phone: "",
    program: "",
    sname: "",
    status: 'pending',
    studentId: "",
    twitter: "",
    level: "",
    evaluations: [],
    evalStage: NA
}

export const blankConstituency: Constituency = {
    name: "", wards: []
}

// blank user
export const blankUser: User = {
    constituency: '',
    evalStage: 'ward',
    position: "",
    ward: '',
    docId: '',
    email: 'admire@gmail.com',
    evaluatorType: 'mass-bnf',
    fname: "",
    gender: 'M',
    omang: "",
    phone: "",
    role: 'evaluator',
    sname: "",
    uid: "",
    deleted: false
}

// list of institutions with names
export const institutions: Institution[] = [
    { level: 'junior', names: ['Botswana Junior Schools']},
    { level: 'senior', names: ['Letlhakane','Lotsane','Madiba','Matshekge','Moeng','SPSS','Shashe River','Shoshong','Swaneng','Mc Connell','Nata','Mmadinare','Ghanzi','Matsha','Molefhi','KGari Sechele','Mogoditshane','Maun','FSS','Msunga','Mater Spei','Lobatse','Gaborone','Kagiso','Ledumang','MOeding','Naledi','St Josephs','Goodhope','Moshupa','Seepapitso','Shakawe','Tsabong','Mahupu']},
    {
        level: 'university',
        names: ['University of Botswana', 'Limkokwing University of Creative Technology', 'Botswana University of Agriculture and Natural Resources', 'Botswana International University of Science and Technology', 'Botho University', 'BUAN','BAC','DDT','Kgolagano College','GTC','Botswana Open University','LKC','IDM','GUC','Megasize','Mancosa','OCAAT','New Era','Tlokweng of Education','GIPS','ABM','Ba Isago']
    }
]

// list of constituencies and their wards
export const constituencies: Constituency[] = [
    {name: 'CHOBE', wards: ['KASANE CENTRAL', 'CHOBE ENCLAVE EAST', 'PANDAMATENGA', 'OLD KAZUNGULA-LESOMA']},
    {name: 'MAUN EAST', wards: ['THAMALAKANE SOUTH', 'THAMALAKANE NORTH', 'MATLAPANA', 'DISANA','BOSEJA SOUTH', 'GXHABARA', 'CHANOGA-PHUDUHUDU','SANKUYO-SHOROBE','KHWAI-MABABE']},
    
    {name: 'MAUN WEST', wards: ['NXARAGA', 'MOETI', 'SHASHE', 'BOMBADI-THITO','BOYEI']},
    {name: 'NGAMI', wards: ['ETSHA 13-JAO', 'ETSHA 6', 'GUMARE-TUBU SOUTH', 'TSAU-MAKAKUNG','SEHITHWA-KARENG', 'BODIBENG-BOTHATOGO-SOMELO']},
    
    {name: 'OKAVANGO', wards: ['GANI-NXAUNXAU', 'SHAKAWE-MOHEMBO WEST', 'SEPOPA-IKOGA', 'SERONGA-GUNOTSOGA','NGARANGE-MOGOTHO']},
    {name: 'TATI EAST', wards: ['TSHESEBE', 'THEMASHANGA', 'TSAMAYA', 'SIVIYA','DITLADI','TATI SIDING EAST']},
    
    {name: 'TATI WEST', wards: ['MOROKA']},
    {name: 'FRANCISTOWN EAST', wards: ['SATELLITE NORTH', 'DONGA', 'NTSHE']},
    
    {name: 'FRANCISTOWN SOUTH', wards: ['SOMERSET EAST', 'BOIKHUTSO', 'KAGELENG', 'PHILLIP MATANTE WEST','IPOPENG']},
    {name: 'FRANCISTOWN WEST', wards: ['MONARCH SOUTH', 'KANANA', 'MOSELEWAPULA']},
    
    {name: 'NATA - GWETA', wards: ['NATA EAST']},
    {name: 'NKANGE', wards: ['MAITENGWE NORTH', 'MAITENGWE SOUTH', 'TUTUME EAST', 'MATOBO-GOSHWE']},
    
    {name: 'SHASHE WEST', wards: ['NSHAKAZHOGWE', 'SEBINA', 'MAROBELA']},
    {name: 'TONOTA', wards: ['TONOTA SOUTH EAST', 'TONOTA WEST', 'MANDUNYANE', 'SHASHE-MOOKE']},
    
    {name: 'BOBONONG', wards: ['MABUMAHIBIDU', 'DANDANE-MABELENG', 'TSETSEBYE']},
    {name: 'MMADINARE', wards: ['SEFHOPHE', 'TAMASANE', 'MOGAPINYANA']},
    
    {name: 'SELIBE PHIKWE EAST', wards: ['BOTSHABELO CENTRAL']},
    {name: 'SELIBE PHIKWE WEST', wards: ['HOSPITAL']},
    
    {name: 'LERALA - MAUNATLALA', wards: ['GOO-TAU', 'LERALA WEST', 'SEOLWANE']},
    {name: 'PALAPYE', wards: ['MMALEKOKOPU', 'MMAPHULA WEST', 'MMAPHULA CENTRAL', 'LOTSANE-THOMADITHOTSE']},
    
    {name: 'SEFHARE - RAMOKGONAMI', wards: ['TUMASERA-SELEKA', 'MACHANENG-MOSHOPHA']},
    {name: 'MAHALAPYE EAST', wards: ['BOSEJA-FLOWERTOWN', 'MOOKANE']},
    
    {name: 'MAHALAPYE WEST', wards: ['MAHALAPYE NORTH', 'MADIBA', 'MAHALAPYE CENTRAL', 'NGAKAAGAE','TSHIKINYEGA','PARWE']},
    {name: 'SHOSHONG', wards: ['MMUTLANE-BONWAPITSE', 'SHOSHONG NORTH', 'DIBETE']},
    
    {name: 'SEROWE NORTH', wards: ['TIDIMALO', 'MOTETSHWANE','NEWTOWN','SWANENG','PATIKWANE','TSHIMOYAPULA']},
    {name: 'SEROWE SOUTH', wards: ['MANONNYE', 'SEROWE SOUTH CENTRAL', 'RADISELE', 'MOGOME-MOKGWARE','MOIYABANA']},
    
    {name: 'SEROWE WEST', wards: ['PALAMAOKUE', 'KGOSING', 'SEROKOLWANE', 'DIMAJWE']},
    {name: 'BOTETI EAST', wards: ['KHWEE']},
    
    {name: 'BOTETI WEST', wards: ['MAKALAMABEDI-MOTOPI', 'ORAPA', 'TSIENYANE WEST', 'TSIENYANE WEST']},
    {name: 'MOCHUDI EAST', wards: ['BOKAA', 'SIKWANE-MABALANE', 'BOSEJA NORTH', 'BOSEJA SOUTH']},
    
    {name: 'MOCHUDI WEST', wards: ['RASESA', 'PILANE', 'MORWA', 'MOSANTA','NTSHINOGE','MAKGOPHANA']},
    {name: 'GABORONE CENTRAL', wards: ['NOTWANE', 'MARUAPULA']},
    
    {name: 'GABORONE NORTH', wards: ['TSOGANG', 'LEDUMANG', 'PHAKALANE', 'MARANG-TSHOLOFELO EAST','MARAPOATHUTLWA','SEBELE']},
    {name: 'GABORONE SOUTH', wards: ['NALEDI SOUTH', 'NALEDI CENTRAL', 'NALEDI NORTH', 'BABUSI','BONTLENG','SELEMELA']},
    
    {name: 'GABORONE - BONNINGTON NORTH', wards: ['TLOGATLOGA', 'DIPHETOGO', 'BOSELE', 'ITUMELENG','BOSEJA']},
    {name: 'GABORONE - BONNINGTON SOUTH', wards: ['MOSEKANGWETSI', 'KGALE VIEW']},
    
    {name: 'TLOKWENG', wards: ['SEFOKE', 'LESUNYANENG', 'TLOKWENG CENTRAL']},
    {name: 'RAMOTSWA', wards: ['SEPITSWANA', 'MAGOPANE', 'MOGOBANE', 'OTSE SOUTH','MOTHUBAKWANE','KELEMOGILE','KGOSING','MAKOLOBOTHO','LESETLHANA']},
    
    {name: 'MOGODITSHANE', wards: ['NKOYAPHIRI CENTRAL', 'LEDUMADUMANE WEST', 'LEDUMADUMANE EAST']},
    {name: 'GABANE - MMANKGODI', wards: ['MMANKGODI NORTH', 'GABANE SOUTH WEST', 'GABANE SOUTH EAST', 'TSOLAMOSESE']},
    
    {name: 'THAMAGA - KUMAKWANE', wards: ['LOSILAKGOKONG-KOTOLANAME', 'THAMAGA WEST', 'THAMAGA NORTH', 'THAMAGA SOUTH','GAKGATLA-RAMAPHATLE','KUMAKWANE']},
    {name: 'MOLEPOLOLE - NORTH', wards: ['BORIBAMO EAST', 'NTLOOLENGWAE']},
    
    {name: 'MOLEPOLOLE - SOUTH', wards: ['LEKGWAPHENG', 'LOOLOGA', 'MOKGOPEETSANE']},
    {name: 'LENTSWELETAU - MMOPANE', wards: ['HATSALATLADI', 'MAHETLWE', 'LENTSWELETAU EAST', 'GAKUTO-KOPONG WEST','MMOPANE']},
    
    {name: 'LETLHAKENG - LEPHEPHE', wards: ['MANTSHWABISI', 'NGWARE', 'SOJWE', 'BOATLANAME-LEOLOGANE']},
    {name: 'TAKATOKWANE', wards: ['SALAJWE']},
    
    {name: 'LOBATSE', wards: ['TSOPENG NORTH', 'TSOPENG SOUTH', 'BOTOKA', 'PELENG EAST','NEWLOOK-MAIPEI','WOODHALL','LOBATSE FARMS']},
    {name: 'GOODHOPE - MABULE', wards: ['GOODHOPE', 'PITSANE', 'RAKHUNA', 'RAMATLABAMA','HEBRON','MOKGOMANE-METLOJANE','GA-MOLOPO','MMAKGORI']},
    
    {name: 'MMATHETHE - MOLAPOWABOJANG', wards: ['LOROLWANE', 'METLOBO-MAGORIAPITSE', 'MOLAPOWABOJANG WEST', 'MOLAPOWABOJANG EAST']},
    {name: 'KANYE NORTH', wards: ['GASEGOGWANE', 'KGOSING', 'BAGAMI', 'DILOLWE','RANAKA','KEBUANG','NTLHANTLHE-LEKGOLOBOTLO']},
    
    {name: 'KANYE SOUTH', wards: ['LOTLHAKANE EAST', 'KGWAKGWE', 'NYOROSI CENTRAL', 'NYOROSI WEST']},
    {name: 'JWANENG - MABUTSANE', wards: ['KHAKHEA', 'NGAMI', 'RAPHALANE', 'MOLOPO']},
    
    {name: 'KGALAGADI NORTH', wards: ['TSHANE-LOKGWABE', 'LEHUTUTU-MAAKE']},
    {name: 'KGALAGADI SOUTH', wards: ['OMAWENENO-KISA-MARALALENG','TSABONG EAST','TSABONG NORTH','']},
    
    {name: 'GHANZI NORTH', wards: ['GHANZI TOWNSHIP WEST', 'MORAMA']},

]
