import React, {useContext} from 'react';
import {
    Button, Col, Row, Space, Typography,
// Carousel
} from "antd";
import '../styles/home.less';
import {Link} from 'react-router-dom';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'
// for the carousel
import img1 from '../assets/dual_logo.jpeg';
import img2 from '../assets/flag.jpg';
import {FileAddOutlined} from "@ant-design/icons";

const {Title} = Typography;

const contentStyle = {
    height: '160px',
    color: '#fff',
    width: '100%',
    lineHeight: '160px',
    textAlign: 'center' as const,
    // background: '#364d79',
};

const Home = () => {

    const imageSrc: any[] = [img1, img2]

    return (
        <Space direction={'vertical'} size={'middle'} align={'center'} className={'home-main'}>
            <Title className={'title'}>
                <strong>Botswana &nbsp;&nbsp; National &nbsp;&nbsp; Front</strong>
            </Title>
            <Title level={3} className={'subtitle'}>
                <strong>MEMBERSHIP REGISTRATION</strong>
            </Title>

            <Row align={'middle'} gutter={[30, 15]} justify={'space-around'}>
                <Col>
                    <Link to={'apply/bnf'}>
                        <Button className={'btn-apply'} block type={'primary'} icon={<FileAddOutlined />}>APPLY &nbsp; BNF</Button>
                    </Link>
                </Col>
                <Col >
                    <Link to={'apply/mass-bnf'}>
                        <Button className={'btn-apply'} block type={'primary'} icon={<FileAddOutlined />}>APPLY &nbsp; MASS-BNF</Button>
                    </Link>
                </Col>
            </Row>

            {/*<div className={'carousel'}>*/}
            {/*    <Carousel autoPlay={true} infiniteLoop dynamicHeight={true} showArrows={false}>*/}
            {/*        <div key={'1'}>*/}
            {/*            <img src={img1}  />*/}
            {/*        </div>*/}
            {/*        <div key={'2'}>*/}
            {/*            <img src={img2}/>*/}
            {/*        </div>*/}
            {/*    </Carousel>*/}
            {/*</div>*/}

            {/*<div>*/}
                <Carousel autoPlay={true} infiniteLoop showArrows={false} emulateTouch swipeable showThumbs={false} showStatus={false}>
                    <div key={'1'} className={'carousel-custom'}>
                        <img src={img1}/>
                    </div>
                    <div key={'2'} className={'carousel-custom'}>
                        <img src={img2}/>
                    </div>
                </Carousel>
            {/*</div>*/}

            {/*<Carousel autoplay className={'carousel'}>*/}
            {/*    <div>*/}
            {/*        /!*<h3 style={contentStyle}>1</h3>*!/*/}
            {/*        <img src={img2} className={'content'} />*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        /!*<h3 style={contentStyle}>2</h3>*!/*/}
            {/*        <img src={img1} className={'content'} />*/}

            {/*    </div>*/}
            {/*</Carousel>*/}

        </Space>
    );
};

export default Home;