import React, {useContext} from 'react';
import { Menu, Grid } from 'antd';
import '../../styles/components/Navbar2.less'
import {
    DesktopOutlined,
    FileAddOutlined,
    HomeOutlined,
    PlusOutlined,
    SearchOutlined,
    UserOutlined
} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {AuthContext} from "../../auth/Auth";
import {AppContext} from "../../App";
import RightMenu from "./RightMenu";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const { useBreakpoint } = Grid;

const LeftMenu = () => {
  const { lg , md, sm, xs} = useBreakpoint();
    let history = useHistory();
    // stores logged in user
    const {currentUser} = useContext(AuthContext);
    const {api, setUser, user} = useContext(AppContext);

    const redirect = (key: any) => {
        // get menu path and reverse it
        let keyPath: string[] = key.keyPath.reverse();
        // remove rc-menu-more from keypath if present
        keyPath = keyPath.filter(k =>  k!=='rc-menu-more' )
        // get path string for url
        let path: string = keyPath.join('/');
        // if path === home then just do /
        if (path === 'home') path = '';
        history.push(`/${path}`);
    }

  return (

      <Menu theme="dark" mode={(lg) ? "horizontal" : "inline"} onSelect={(key) => redirect(key)}
            className={'menu'}>

          <Menu.Item key="home" icon={<HomeOutlined/>} style={{color: 'white'}}>HOME</Menu.Item>

          <SubMenu key="apply" icon={<FileAddOutlined/>} title="APPLY" style={{color: 'white'}}>
              <Menu.Item key="mass-bnf">MASS-BNF</Menu.Item>
              <Menu.Item key="bnf">BNF</Menu.Item>
          </SubMenu>

          {/*only show when user is logged in*/}
          {currentUser && user &&
          <>
              <Menu.Item key="evaluate" icon={<DesktopOutlined/>} style={{color: 'white'}}>EVALUATE</Menu.Item>
              <Menu.Item key="search" icon={<SearchOutlined/>} style={{color: 'white'}}>SEARCH</Menu.Item>
              {/*check if current user is admin*/}
              {user.role === 'admin' &&
              <SubMenu key="users" icon={<UserOutlined/>} title="USERS" style={{color: 'white'}}>
                  <Menu.Item key="add-user" icon={<PlusOutlined/>}>ADD USER</Menu.Item>
                  <Menu.Item key="search-users" icon={<SearchOutlined/>}>SEARCH USERS</Menu.Item>
              </SubMenu>
              }
          </>
          }
      </Menu>

  );
}

export default LeftMenu;
