import React, {useContext, useEffect, useState} from 'react';
import {
    ApplicationStatus,
    BnfApplication, EvaluationStage,
    EvaluatorType,
    MassBnfApplication,
    PropsApplicationsGrid
} from "../tools/types";
import {blankUser} from "../tools/constants";
import {useMutation} from "react-query";
import {Button, Card, Col, Input, message, Popconfirm, Row, Space, Typography} from "antd";
import {AppContext} from "../App";
import {getApplicationType} from "../tools/functions";
import CardMassBnfApplication from "./cards/CardMassBnfApplication";
import CardBnfApplication from "./cards/CardBnfApplication";
import '../styles/components/ApplicatiolnsGrid.less';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';


const {TextArea} = Input;


// app styles
const styles = {
    comment: {
        marginBottom: '20px'
    },
    btn: {
        height: '45px',
        color: 'white',
        borderRadius: '8px',
        borderWidth: '2px',
        fontWeight: 'bold' as const,
        fontSize: '16px'
    },
    btnApprove: {
        backgroundColor: '#08ae22'
    },
    btnDeny: {
        backgroundColor: '#f82e2e'
    }
}

const ApplicationsGrid: React.FC<PropsApplicationsGrid> = ({applications}) => {
    const {user, api} = useContext(AppContext);
    const {
        isSuccess: isEvaluateSuccess,
        isError: isEvaluateError,
        isLoading: isEvaluateLoading,
        reset: evaluateReset, error: evaluateError,
        mutate: evaluateM
    } = useMutation('evaluate', api.evaluate, {retry: 2});
    // comments by application id
    //const [comments, setComments] = useState({});
    const [comment, setComment] = useState<string>('');

    // control error message
    useEffect(() => {
        if (isEvaluateLoading) message.loading('Submitting Evaluation...');
        if (isEvaluateSuccess) message.success('Evaluation successful!').then(() => evaluateReset());
        if (isEvaluateError) message.error('An error occured while evaluating.').then(() => api.logError(evaluateError));
    }, [ isEvaluateError, isEvaluateSuccess, isEvaluateLoading])

    const evaluate = (app: MassBnfApplication | BnfApplication, action: ApplicationStatus) => {

        evaluateM({
            user: user || blankUser,
            app: app,
            status: action,
            comment: comment
        })
    }

    // whether or not a user can approve/deny an application
    const canEvaluate = (app: BnfApplication | MassBnfApplication) : boolean => {
        // admins can evaluate anything
        if(user?.role === 'admin') return true;

        // check the type of application
        const appType: EvaluatorType = getApplicationType(app);

        // for massbnf, allow anything not bnf
        if(appType === 'mass-bnf' && user?.evaluatorType === 'mass-bnf') return true;

        // for bnf
        if(appType === 'bnf'){
            const bnfApp: BnfApplication = app as BnfApplication;
            // evaluation stage of the user -- ward, constituency, secgen
            const userStage: EvaluationStage = user?.evalStage? user?.evalStage : 'n/a';

            // match application's evaluation stage with the evaluator's stage
            if(bnfApp.evalStage === userStage){
                // for ward evaluator, allow only in corresponding constituency and ward
                if(userStage==='ward' && user?.constituency === bnfApp.constituency && user?.ward === bnfApp.ward) return true;

                // for constituency evaluator, allow only in corresponding constituency
                if(userStage==='constituency' && user?.constituency === bnfApp.constituency) return true;

                // for secgen, allow all
                if(userStage==='secgen') return true;
            }
        }

        return false;
    }

    return (
        // <Space direction={'vertical'} size={'large'} align="center" style={{width: '100%'}}>
    <Space direction={'vertical'} size={'large'} style={{width: '100%'}} align={'center'}>

        {applications.map((app) =>
            <Card key={app.docId} hoverable style={{borderBottom: '3px solid #FFC533'}}>

                <Row gutter={[16, 16]}>

                    {/*left panel with card*/}
                    <Col xs={24} sm={16} md={16} lg={16}>

                        {/*send app to the correct card*/}
                        {getApplicationType(app) === 'mass-bnf'
                            ?
                            // mass-bnf evaluators
                            <CardMassBnfApplication data={app as MassBnfApplication}/>
                            :
                            // admin and bnf evaluators
                            <CardBnfApplication data={app as BnfApplication}/>
                        }

                    </Col>

                    {/*right side with evaluation*/}
                    <Col xs={24} sm={8} md={8} lg={8}>
                        <Card style={{ textAlign: 'center' as const, height: '100%', borderRadius: '8px'}}>
                        {/*    <Space direction={'vertical'} size={'large'} align={'center'}*/}
                        {/*           style={{width: '100%', height: '100%', padding: '15px'}}>*/}
                                <h3 className={'ev-title'}><u>Evaluation</u></h3>
                                <div className={'comment'}>
                                    <TextArea
                                        rows={8}
                                        allowClear
                                        name={'comment'}
                                        placeholder={'Why are you approving or denying this application?'}
                                        onChange={(e) => setComment( e.target.value)}
                                    />
                                </div>

                                {/*<Space size={'middle'} align={'center'} style={{width: '100%'}}>*/}
                                <Popconfirm placement="top" title={'APPROVE this application?'}
                                            onConfirm={() => evaluate(app, 'approved')} okText="YES"
                                            cancelText="NO" disabled={!canEvaluate(app)}>
                                    <Button  type={'primary'} block className={'ev-item'} icon={<CheckOutlined />}  style={{...styles.btn, ...styles.btnApprove}} disabled={!canEvaluate(app)}>
                                        APPROVE
                                    </Button>
                                </Popconfirm>

                                <Popconfirm  placement="bottom" title={'DENY this application?'}
                                            onConfirm={() => evaluate(app, 'denied')} okText="YES" cancelText="NO" disabled={!canEvaluate(app)}>
                                    <Button type={'primary'} block  icon={<CloseOutlined />} style={{...styles.btn, ...styles.btnDeny}} disabled={!canEvaluate(app)}>
                                        DENY
                                    </Button>
                                </Popconfirm>
                                {/*</Space>*/}

                            {/*</Space>*/}
                         </Card>
                    </Col>

                </Row>

            </Card>
        )}

        {applications.length === 0 && <p>NO RESULTS</p>}
    </Space>
    );
};

export default ApplicationsGrid;