import React from 'react';
import {Button, Col, Form, Input, Radio, Row, Select, Space} from "antd";
import {UserAddOutlined} from "@ant-design/icons";
import {PropsUserForm} from "../../tools/types";
import {evaluationStages, evaluatorTypes, userRoles} from "../../tools/constants";
import ConstituencyWardSelectors from "../ConstituencyWardSelectors";

const {Option} = Select;

const UserForm: React.FC<PropsUserForm> = ({submitForm, isLoading, initialValues, form}) => {

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={submitForm}
            // only use if actually supplied
            initialValues = {initialValues}
        >
            <Row justify="space-around" align="middle" gutter={16}>
                {/*<Col span={24}>*/}
                {/*    <h2><u><strong>User's Details</strong></u></h2>*/}
                {/*</Col>*/}

                {/*Surname*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="sname"
                        label="Surname"
                        className={'form-item'}
                        rules={[{ required: true, message: 'Please input surname!' }]}
                    >
                        <Input placeholder="Bakgosi" />
                    </Form.Item>
                </Col>

                {/*First Name*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="fname"
                        label="First Name(s)"
                        className={'form-item'}
                        rules={[{ required: true, message: 'Please input first name(s)!' }]}
                    >
                        <Input placeholder="Botho Kago" />
                    </Form.Item>
                </Col>

                {/*OMANG*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="omang"
                        label="Omang / Passport Number"
                        className={'form-item'}
                        rules={[{ required: true, message: 'Please input the Omang/Passport number!' }]}
                    >
                        <Input placeholder="1234567" />
                    </Form.Item>
                </Col>

                {/*Gender*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item name="gender" label="Gender" style={{textAlign: 'left' as const}}
                               rules={[{ required: true, message: 'Please input the gender' }]}
                    >
                        <Radio.Group>
                            <Space size={'middle'}>
                                <Radio.Button value="M">MALE</Radio.Button>
                                <Radio.Button value="F">FEMALE</Radio.Button>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Col>

                {/*PHONE*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="phone"
                        label="Phone Number"
                        className={'form-item'}
                        rules={[{ required: true, message: 'Please input the phone number' }]}
                    >
                        <Input addonBefore="+267" placeholder={'74123456'} type={'number'}/>
                    </Form.Item>
                </Col>

                {/*EMAIL*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="email"
                        label="Email Address"
                        className={'form-item'}
                        rules={[
                            {type: 'email', message: 'The input is not valid email.'},
                            {required: true, message: 'Please enter your email address.'}]
                        }
                    >
                        <Input placeholder="bakgosib@gmail.com" />
                    </Form.Item>
                </Col>

                <ConstituencyWardSelectors />

                {/*Position*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="position"
                        label="User's Position"
                        className={'form-item'}
                        rules={[{ required: true, message: 'Please input the new user\'s position!' }]}
                    >
                        <Input placeholder="Councillor" />
                    </Form.Item>
                </Col>

                {/*USER ROLE*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="role"
                        label="User Role"
                        // className={'form-item'}
                        rules={[{ required: true, message: 'Please choose user role.' }]}
                    >
                        <Radio.Group>
                            <Space size={'small'}>
                                {userRoles.map(role =>
                                    <Radio.Button key={role} value={role}>{role.toUpperCase()}</Radio.Button>
                                )}
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Col>

                {/*Evaluator type*/}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="evaluatorType"
                        label="Evaluating For"
                        // className={'form-item'}
                        rules={[{ required: true, message: 'Please choose which party the user is evaluating for' }]}
                    >
                        <Select
                            defaultValue={'n/a'}
                        >
                            {evaluatorTypes.map(ev =>
                                <Option key={ev} value={ev}>{ev.toUpperCase()}</Option>
                            )}
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="evalStage"
                        label="Evaluating Stage"
                        // className={'form-item'}
                        rules={[{ required: true, message: 'Please choose which stage the user is evaluating' }]}
                    >
                        <Select
                            defaultValue={'n/a'}
                        >
                                {evaluationStages.map(ev =>
                                    <Option key={ev} value={ev}>{ev.toUpperCase()}</Option>
                                )}
                        </Select>
                    </Form.Item>
                </Col>


                <Col span={24}>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{width: '100%', height: '40px', color: '#0A8A4C'}}
                            size={'large'}
                            loading={isLoading}
                            icon={<UserAddOutlined />}
                        >
                            {/*if there's no initial values, then its an add, otherwise its an edit*/}
                            {initialValues? 'EDIT' : 'ADD' } USER
                        </Button>
                    </Form.Item>
                </Col>


            </Row>
        </Form>
    );
};

export default UserForm;