import React, {useContext, useEffect} from "react";
import {PropsEditUser, User} from "../../tools/types";
import {useMutation} from "react-query";
import {UserForm} from "./users";
import {Form, message} from "antd";
import {AppContext} from "../../App";


const EditUser:React.FC<PropsEditUser> = ({user}) =>{
    const [form] = Form.useForm();
    const {api} = useContext(AppContext);
    // mutation to add user to the db
    const {isLoading, isSuccess, isError, error, mutate, reset} = useMutation('editUser', api.editUser, {retry: 2});

    // controls state messages
    useEffect(() => {
        if (isSuccess) message.success('Successfully edited.').then(()=> {
            // clear the form
            form.resetFields();
            // reset the query
            reset();
        });
        if (isError) message.error('An error occurred. Please try again.').then(()=> api.logError(error));
    }, [isSuccess, isError])

    // function to call when editing user
    const editUser = (values: any) => {
        // override the edit item with values from the form, anything not in the form is left as is
        const us: User = {...user, ...values, deleted: false, email: values.email.trim(), phone: values.phone.trim()};
        mutate(us);
        // update grid view
        user = us;
    }

    return <UserForm  isLoading={isLoading} submitForm={editUser} initialValues={user} form={form}/>
}

export default EditUser;