import React, {Dispatch, useEffect, useState} from 'react';
import {User} from "./tools/types";
import './styles/App.less';
import {API} from "./tools/api";
import ErrorBoundary from "./components/ErrorBoundary";
import {AuthProvider} from "./auth/Auth";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Navbar2 from "./components/navbar/Navbar2";

// pages
import {ApplyMassBnf, Evaluate, ApplyBnf, Home, Login, Search, AddUser, SearchUsers} from "./pages/pages";

// for react query -- Create a client
import {QueryClient, QueryClientProvider} from "react-query";
import {Breadcrumb, Layout} from "antd";
import Navbar from "./components/Navbar";
import PrivateRoute from './auth/PrivateRoute';
import {blankUser} from './tools/constants';

// context
export const AppContext = React.createContext<{ user: User | undefined, setUser: Function, api: API, setBreadcrumbs: Function }>(undefined!);
const queryClient = new QueryClient();
const {Content, Footer} = Layout;

// const CountStateContext = React.createContext<{state: State; dispatch: Dispatch} | undefined>(undefined)

function App() {
    // stores current user
    const [user, setUser] = useState<User | undefined>(undefined);
    // stores api
    const [api, setAPI] = useState(new API());
    // stores breadcrumbs
    const [breadcrumbs, setBreadcrumbs] = useState(['Home']);


    // get data when home loads
    useEffect(() => {
        const userSession: User = JSON.parse(sessionStorage.getItem('user') as string);
        if (userSession) {
            // available in session
            setUser(userSession);
            // set api's user value
            api.user = userSession;
        }
    }, [])


    return (
        <QueryClientProvider client={queryClient}>
            <ErrorBoundary>
                <AuthProvider>
                    <AppContext.Provider value={{user, setUser, api, setBreadcrumbs}}>
                        <div className="App">
                            <Router>
                                <Switch>
                                    <Route exact path="/login" component={Login}/>

                                    {/*<Route exact path="/register/mass-bnf" component={RegisterMassBnf} />*/}
                                    <Layout>
                                        {/*<Navbar setBreadcrumbs={setBreadcrumbs}/>*/}
                                        <Navbar2 />
                                        <Content className="site-layout" style={{padding: '3%', marginTop: 64}}>
                                            {/*<Breadcrumb style={{ margin: '16px 0' }}>*/}
                                            {/*    {breadcrumbs.map(crumb =>*/}
                                            {/*        <Breadcrumb.Item key={crumb}>{crumb.toUpperCase()}</Breadcrumb.Item>*/}
                                            {/*    )}*/}
                                            {/*</Breadcrumb>*/}
                                            <div className="site-layout-background" style={{
                                                padding: '2%',
                                                minHeight: 380,
                                                backgroundColor: 'white',
                                                // marginTop: '3%'
                                            }}>

                                                <Route exact path="/" component={Home}/>
                                                <Route exact path="/apply/mass-bnf" component={ApplyMassBnf}/>
                                                <Route exact path="/apply/bnf" component={ApplyBnf}/>
                                                <PrivateRoute exact path="/evaluate" component={Evaluate}/>
                                                <PrivateRoute exact path="/search" component={Search}/>
                                                <PrivateRoute exact path="/users/add-user" component={AddUser}/>
                                                <PrivateRoute exact path="/users/search-users" component={SearchUsers}/>

                                            </div>
                                        </Content>
                                        <Footer style={{textAlign: 'center'}}>Botswana National Front
                                            ©{new Date().getUTCFullYear()}</Footer>
                                    </Layout>
                                </Switch>
                            </Router>
                        </div>
                    </AppContext.Provider>
                </AuthProvider>
            </ErrorBoundary>
        </QueryClientProvider>
    );
}

export default App;
