import React from 'react';
import {PropsCardMassBnfApplication} from "../../tools/types";
import {Avatar, Card, Col, Collapse, Row, Space, Steps} from "antd";
import imgM from "../../assets/male_avatar.png";
import imgF from "../../assets/female_avatar.png";
import {
    createFullName,
    dateTimeToString,
    dateToString,
    getDurationString,
    upperCaseFirstLetter
} from "../../tools/functions";
import { FieldTimeOutlined  } from '@ant-design/icons';

const { Panel } = Collapse;
// for stepper
const {Step} = Steps;

const CardMassBnfApplication: React.FC<PropsCardMassBnfApplication> = ({data, hoverable}) => {
    return (
        <Card style={{textAlign: 'left'}} hoverable={hoverable || false}>
            <Row gutter={[0, 8]}>

                {/*Name and Avatar*/}
                <Col span={24}>

                    <Row justify="space-around" align="middle">
                        {/*avatar*/}
                        <Col span={4}>
                            <Avatar
                                // size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                                size={60}
                                src={(data.gender === 'M') ? imgM : imgF}
                            />
                        </Col>

                        {/*full Name and uni*/}
                        <Col span={20}>
                            <h2>{createFullName(data.sname, data.fname)}, {getDurationString(data.dob, new Date())} old</h2>
                            <Space>
                                {data.status === 'pending' && <h3 style={{color: 'orange'}}> <FieldTimeOutlined /> <strong>PENDING</strong></h3>}
                                {data.status === 'approved' && <h3 style={{color: 'green'}}> <FieldTimeOutlined /> <strong>APPROVED</strong></h3>}
                                {data.status === 'denied' && <h3 style={{color: 'red'}}> <FieldTimeOutlined /> <strong>DENIED</strong></h3>}
                                {data.status !== 'pending' &&
                                    <h3 > <strong>#{data.membershipNumber}</strong></h3>
                                }
                            </Space>
                        </Col>

                    </Row>

                </Col>

                <Col span={12}>
                    <h4>Applied On: </h4>
                </Col>

                <Col span={12}>
                    <h4> {dateTimeToString(data.appliedOn)} </h4>
                </Col>

                <Col span={12}>
                    <h4>Date of Birth: </h4>
                </Col>

                <Col span={12}>
                    <h4> {dateToString(data.dob)} </h4>
                </Col>

                {/*Omang label*/}
                <Col span={12}>
                    <h4>Omang: </h4>
                </Col>

                {/*Omang number*/}
                <Col span={12}>
                    <h4> {data.omang} </h4>
                </Col>

                {/*uni label*/}
                <Col span={12}>
                    <h4>Level of Study: </h4>
                </Col>

                {/*uni  */}
                <Col span={12}>
                    <h4> {data.level} </h4>
                </Col>

                {/*stu id label*/}
                <Col span={12}>
                    <h4>School: </h4>
                </Col>

                {/*student id */}
                <Col span={12}>
                    <h4> {data.school} </h4>
                </Col>

                {/*uni label*/}
                <Col span={12}>
                    <h4>Program of Study: </h4>
                </Col>

                {/*student id */}
                <Col span={12}>
                    <h4> {data.program} </h4>
                </Col>

                {/*stu id label*/}
                <Col span={12}>
                    <h4>Student ID: </h4>
                </Col>

                {/*student id */}
                <Col span={12}>
                    <h4> {data.studentId} </h4>
                </Col>

                {/*Email label*/}
                <Col span={12}>
                    <h4>Email: </h4>
                </Col>

                {/*Email number*/}
                <Col span={12}>
                    <h4> {data.email}</h4>
                </Col>

                {/*Phone label*/}
                <Col span={12}>
                    <h4>Phone: </h4>
                </Col>

                {/*Phone number*/}
                <Col span={12}>
                    <h4> {data.phone} </h4>
                </Col>

                {data.facebook &&
                    <>
                        <Col span={12}>
                            <h4>Facebook Username: </h4>
                        </Col>

                        <Col span={12}>
                            <h4> {data.facebook} </h4>
                        </Col>
                    </>
                }

                {data.instagram &&
                    <>
                        <Col span={12}>
                            <h4>Instagram Handle: </h4>
                        </Col>

                        <Col span={12}>
                            <h4> {data.instagram} </h4>
                        </Col>
                    </>
                }

                {data.twitter &&
                <>
                    <Col span={12}>
                        <h4>Twitter Handle: </h4>
                    </Col>

                    <Col span={12}>
                        <h4> {data.twitter} </h4>
                    </Col>
                </>
                }

                {/*details of the evaluator if not longer pending*/}
                {data.status !== 'pending' &&
                    <Col span={24}>
                        <Collapse>
                            {/*job cars*/}
                            <Panel header={'EVALUATIONS'} key="evaluator" >
                                <Steps progressDot current={data.evaluations.length-1} direction="vertical" size={'small'}>
                                    {data.evaluations.map((ev,index) =>
                                        <Step
                                            key={index}
                                            title={(<p><strong>{upperCaseFirstLetter(ev.status)}</strong></p>)}
                                            // @ts-ignore
                                            // description={`${ev.evaluatedBy}, ${dateTimeToString(ev.evaluatedOn.toDate())}, ${ev.evalStage} with comment: ${ev.comment} `}
                                            description={(
                                                <div style={{lineHeight: '10px'}}>
                                                    <p>On: {dateTimeToString(ev.evaluatedOn)}</p>
                                                    <p>By: {ev.evaluatedBy} </p>
                                                    <p>From: {ev.ward}, {ev.constituency} </p>
                                                    <p>Contacts: {ev.contactDetails} </p>
                                                    {ev.comment && <p>Comment: <i>{ev.comment}</i> </p>}
                                                </div>
                                            )}
                                        />
                                    )}
                                </Steps>
                            </Panel>
                        </Collapse>
                    </Col>
                }



            </Row>

        </Card>
    );
};

export default CardMassBnfApplication;