import React, {Component, useContext, useState} from 'react';
import LeftMenu from './LeftMenu'
import RightMenu from './RightMenu'
import {Drawer, Button, Layout, Menu, Grid, Col, Tooltip, Avatar, Row, Space, Modal, Typography} from 'antd';
import '../../styles/components/Navbar2.less';
import {
    DesktopOutlined,
    FileAddOutlined,
    HomeOutlined,
    LogoutOutlined, PlusOutlined,
    SearchOutlined,
    MenuOutlined, CloseOutlined
} from "@ant-design/icons";
import flag from "../../assets/flag.jpg";
import {useHistory} from "react-router-dom";
import {AuthContext} from "../../auth/Auth";
import {AppContext} from "../../App";
import {upperCaseFirstLetter} from "../../tools/functions";
import UserCard from "../users/UserCard";
import {blankUser} from "../../tools/constants";

const {Header, Sider} = Layout;
const {useBreakpoint} = Grid;
const {SubMenu} = Menu;
const {Title} = Typography;

const styles = {
    btnLogout: {
        color: 'white',
        backgroundColor: 'inherit',
        border: '2px solid #FFC533',
        borderRadius: '5px'
    }
}

const Navbar2 = () => {
    const [showDrawer, setShowDrawer] = useState<boolean>(false);
    let history = useHistory();
    const { lg , md, sm, xs} = useBreakpoint();
    // stores logged in user
    const {currentUser} = useContext(AuthContext);
    const {api, setUser, user} = useContext(AppContext);
    // shows full details of logged in user
    const [showUserCard, setShowUserCard] = useState<boolean>(false);

    // open login page
    const login = () => {
        // setBreadcrumbs(['Login']);
        history.push('/login');
    }

    // logout current user
    const logout = async () => {
        await api.signOut();
        // clear user context
        setUser(undefined);
        // take bakc to login page
        history.push('/login');
    };

    // details for the tootlip of a logged in user
    const getTooltipDetails = (): string => {
        let str: string = `${user?.sname} ${user?.fname}, ${upperCaseFirstLetter(user?.role || '')}`;
        return str;
    }

    return (
        <Header className="menuBar">
            <div>
                <img src={flag} alt={'logo'} className="logo"/>
            </div>
            <div className="menuCon">


                {(lg) &&

                    <Row justify={'space-between'}>
                        <Col span={21}>
                            <LeftMenu/>
                        </Col>

                        <Col span={3}>
                            {currentUser &&
                                <Space>
                                    <div onClick={() => setShowUserCard(true)}>
                                        <Tooltip placement="bottom" title={getTooltipDetails()}>

                                            {/*<Avatar size={'large'} style={{ color: 'white', backgroundColor: 'inherit' , border: '2px solid #FFC533' ,cursor: 'pointer'}}>*/}
                                            <Avatar size={'large'} style={{
                                                color: 'white',
                                                backgroundColor: 'inherit',
                                                border: '2px solid #FFC533',
                                                cursor: 'pointer'
                                            }}>
                                                {(user?.sname[0] + ' ' + user?.fname[0]).toUpperCase()}
                                            </Avatar>
                                        </Tooltip>
                                    </div>
                                    <Button
                                        icon={<LogoutOutlined/>}
                                        type={'primary'}
                                        onClick={logout}
                                        style={styles.btnLogout}
                                    >
                                        LOGOUT
                                    </Button>
                                </Space>
                            }
                        </Col>



                    </Row>
                }

                {/*{currentUser &&*/}
                {/*// show when there is a logged in user*/}
                {/*<div >*/}
                {/*    <Row justify={'space-around'} >*/}
                {/*        <Col span={18}>*/}
                {/*            <LeftMenu/>*/}
                {/*        </Col>*/}


                {/*        <Col span={6}>*/}
                {/*            <Space>*/}
                {/*                <div onClick={() => setShowUserCard(true)}>*/}
                {/*                    <Tooltip placement="bottom" title={getTooltipDetails()}>*/}

                {/*                        /!*<Avatar size={'large'} style={{ color: 'white', backgroundColor: 'inherit' , border: '2px solid #FFC533' ,cursor: 'pointer'}}>*!/*/}
                {/*                        <Avatar size={'large'} style={{*/}
                {/*                            color: 'white',*/}
                {/*                            backgroundColor: 'inherit',*/}
                {/*                            border: '2px solid #FFC533',*/}
                {/*                            cursor: 'pointer'*/}
                {/*                        }}>*/}
                {/*                            {(user?.sname[0] + ' ' + user?.fname[0]).toUpperCase()}*/}
                {/*                        </Avatar>*/}
                {/*                    </Tooltip>*/}
                {/*                </div>*/}
                {/*                <Button*/}
                {/*                    icon={<LogoutOutlined/>}*/}
                {/*                    type={'primary'}*/}
                {/*                    onClick={logout}*/}
                {/*                    style={styles.btnLogout}*/}
                {/*                >*/}
                {/*                    LOGOUT*/}
                {/*                </Button>*/}
                {/*            </Space>*/}

                {/*        </Col>*/}

                {/*    </Row>*/}

                {/*</div>*/}
                {/*}*/}

                <div className="barsMenu">
                    <Button type="primary" icon={<MenuOutlined />}
                            style={styles.btnLogout}
                            onClick={() => setShowDrawer(true)}>
                        MENU
                    </Button>
                </div>

                <Drawer
                    title={<h3 style={{color: '#FFC533'}}><MenuOutlined style={{color: '#FFC533'}}/> MENU </h3>}
                    placement="right"
                    closable={true}
                    closeIcon={<CloseOutlined style={{color: '#FFC533'}}/>}
                    onClose={() => setShowDrawer(false)}
                    visible={showDrawer}
                    drawerStyle={{backgroundColor: '#001628'}}
                    headerStyle={{backgroundColor: '#001628', color: '#fff'}}
                >
                    <LeftMenu/>

                    {currentUser &&
                        <Menu theme={'dark'} selectable={false}>
                        <Menu.Item key="profile"
                                   style={{color: 'white'}}
                                   icon={<Avatar
                                       size={'small'}
                                       style={{color: 'white', backgroundColor: 'inherit',}}>
                                       {(user?.sname[0] + ' ' + user?.fname[0]).toUpperCase()}
                                   </Avatar>}
                                   onClick={() => setShowUserCard(true)}
                        >
                            MY PROFILE
                        </Menu.Item>

                        <Menu.Item key="logout" icon={<LogoutOutlined/>} style={{color: '#FFC533'}} onClick={logout}>
                            LOGOUT
                        </Menu.Item>
                    </Menu>
                    }
                </Drawer>

            </div>

            {/*modal used to show currently logged in user's details when avatar is clicked*/}
            <Modal footer={null} visible={showUserCard} onCancel={() => setShowUserCard(false)}>
                <UserCard user={user ? user : blankUser} noActions={true}/>
            </Modal>

        </Header>
    );
}

export default Navbar2;
