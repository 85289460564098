import React, {useEffect, useState} from 'react';
import {Form, Select} from "antd";
import { PropsSearchableSelect, SelectOption} from "../tools/types";
import {constituencies, institutions} from "../tools/constants";
import {stringContains, stringComparator, createSelectObjectsFromArray} from "../tools/functions";

const {Option} = Select;

const SearchableSelect: React.FC<PropsSearchableSelect> = ({ options, optionsType, setSelectedObject,placeholder, label, required = true, name, disabled}) => {

    const [selOptions, setSelOptions] = useState<SelectOption[]>();

    // prevents infinite loop
    useEffect(() => {
        // sort supplied options if there
        if (options){
            setSelOptions(options.sort((a,b) => stringComparator(a.label, b.label)));
        }

        // array fpr storing list from obejects
        let array: string[] = [];
        // for when optionType is supplied
        if (optionsType){
            switch(optionsType){
                // institutions are already in the right format
                case 'universities':
                    institutions.forEach(uni => array.push(uni.level));
                    break;
                // fix constituencies from Constituency objects array
                case "constituencies":
                    constituencies.forEach(c => array.push(c.name));
                    break;
            }

            // create a sorted select objects array and store in state for selector
            setSelOptions(createSelectObjectsFromArray(array));
        }

    } , [optionsType, options])

    return (
        <Form.Item
            name={name}
            label={label}
            // if required is supplied, use that value, otherwise use true -- i.e true by default
            rules={[{required: (required!==undefined)? required : true, message: `Please select ${label}!`}]}
        >

            <Select
                showSearch
                allowClear
                // for disabling
                disabled={disabled || false}
                // for when selecting a single item
                onSelect={(val, option) => {
                    if (setSelectedObject) setSelectedObject(option.data);
                }}
                onClear={() => {
                    if (setSelectedObject) setSelectedObject(undefined)
                }}
                style={{minWidth: 200}}
                placeholder={placeholder}
                optionFilterProp="children"
                filterOption={(input, option) =>
                    stringContains(option?.data.label, input)
                }
                filterSort={(optionA, optionB) =>
                    stringComparator(optionA.data.label, optionB.data.label)
                }
            >
                {selOptions && selOptions.map(option =>
                    <Option key={option.value} value={option.value} data={option}> {option.label} </Option>
                )}

            </Select>

        </Form.Item>
    )
}

export default SearchableSelect;