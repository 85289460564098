import React from 'react';
import CountUp from "react-countup";
import {PropsCustomCountUp} from "../tools/types";
import '../styles/App.less';

const CustomCountUp : React.FC<PropsCustomCountUp> = ({end}) => {
    return (
        <CountUp end={end} prefix={'Total Results: '} className={'count-up'}/>
    );
};

export default CustomCountUp;