import React, {useContext, useState} from 'react';
import {useMutation} from "react-query";
import {AppContext} from "../App";
import {Button, Col, Empty, Form, Input, Row, Select, Space, Typography} from "antd";
import {applicationStatuses, evaluatorTypes, userRoles} from "../tools/constants";
import ConstituencyWardSelectors from "../components/ConstituencyWardSelectors";
import {SearchOutlined} from "@ant-design/icons";
import {EvaluatorType, Filter} from "../tools/types";
import CustomCountUp from "../components/CustomCountUp";
import UserCard from "../components/users/UserCard";
import ApplicationsGrid from "../components/ApplicationsGrid";
import {upperCaseFirstLetter} from "../tools/functions";
import SchoolSelector from "../components/SchoolSelector";

const {Option} = Select;
const {Title} = Typography;

const Search = () => {
    const {api, user} = useContext(AppContext);
    const [form] = Form.useForm();
    // type of political party
    const [party, setParty] = useState<string>();
    // getting user data
    const {
        data,
        isError,
        isLoading,
        isSuccess,
        mutate, error
    } = useMutation('getApplications', api.getApplications, {retry: 2});

    const search = (values: any) => {
        let filters: Filter[] = [];

        // fix fields if supplied
        if (values.omang?.trim()) filters.push({field: 'omang', value: values.omang});
        if (values.status) filters.push({field: 'status', value: values.status});
        if (values.constituency) filters.push({field: 'constituency', value: values.constituency});
        // for ward, constituency must also have a value, otherwise it is disregarded
        if (values.constituency && values.ward) filters.push({field: 'ward', value: values.ward});

        // for cases whereby type was not required since default, then use evaluator type
        let type: EvaluatorType = (!values.type)? user?.evaluatorType : values.type;

        mutate({filters: filters, type: type});
    }

    // create a title depending on the user
    let title: string = '';
    if (user?.role === 'admin') title = 'All';
    else if (user?.role === 'evaluator') title = user?.evaluatorType.toUpperCase();

    return (
        <div style={{textAlign: 'center' as const}}>
            <Title level={3}>SEARCH {title?.toUpperCase()} MEMBERS</Title>

            {/*// search items form*/}
            {/*Search fields form*/}
            <Form
                form={form}
                layout="vertical"
                onFinish={search}
            >
                <Row gutter={[16, 16]}>
                    {/*User's Omang*/}
                    <Col span={24}>
                        <Form.Item
                            name="omang"
                            label="Omang / Passport Number:"
                            className={'form-item'}
                            rules={[{required: false, message: 'Please input the Omang/Passport number!'}]}
                        >
                            <Input placeholder="1234567"/>
                        </Form.Item>
                    </Col>

                    {/*Type of application */}
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item
                            name="type"
                            label="Applying For:"
                            rules={[{
                                // only required for admin who has to select
                                required: user?.role==='admin' ,
                                message: 'Please choose a Political Organisation.'
                            }]}
                        >
                            <Select
                                allowClear
                                onSelect={(type) => setParty(type as string)}
                                placeholder={'Select Political Organisation'}
                                // disable this if the role is not admin
                                disabled={user?.role !== 'admin'}
                                // for evaluators, evaluatorType is the default
                                defaultValue={(user?.role==='evaluator')? user?.evaluatorType : undefined}
                            >
                                {/*exclude n/a*/}
                                {evaluatorTypes.filter(t => t !== 'n/a').map(type =>
                                    <Option key={type} value={type}>{type.toUpperCase()}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>

                    {/*Application status */}
                    <Col xs={24} sm={12} md={12} lg={12}>
                        <Form.Item
                            name="status"
                            label="Application Status:"
                            rules={[{required: false, message: 'Please choose an application status.'}]}
                        >
                            <Select allowClear placeholder={'Select Application Status'}>
                                {/*exclude n/a*/}
                                {applicationStatuses.map(status =>
                                    <Option key={status} value={status}>{status.toUpperCase()}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>

                    {/*hide when user is not admin and evaluatorType is mass-bnf */}
                    <Col span={24} style={{display: (user?.role!=='admin' && user?.evaluatorType==='mass-bnf')? 'none' : ''}}>
                        <Row gutter={[16, 16]}>
                            {/*disable when type is mass-bnf since they dont apply there*/}
                            <ConstituencyWardSelectors required={false} disabled={party==='mass-bnf'}/>
                        </Row>
                    </Col>

                    {/*hide when user is not admin and evaluatorType is mass-bnf */}
                    <Col span={24} style={{display: (user?.role!=='admin' && user?.evaluatorType==='bnf')? 'none' : ''}}>
                        <Row gutter={[16, 16]}>
                            {/*disable when party selected is bnf since they dont apply there*/}
                            <SchoolSelector required={false} disabled={party==='-bnf'}/>
                        </Row>
                    </Col>

                </Row>


                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{width: '100%', height: '40px', color: '#0A8A4C'}}
                        size={'large'}
                        loading={isLoading}
                        icon={<SearchOutlined/>}
                    >
                        SEARCH APPLICATIONS
                    </Button>
                </Form.Item>

            </Form>

            {/*when search is successful*/}
            {isSuccess && data &&
            // Show number of results
            <div>
                <CustomCountUp end={data.length}/>

                {/*Results*/}
                <ApplicationsGrid applications={data}/>
            </div>
            }

            {/*if no data is found*/}
            {isSuccess && data?.length === 0 &&
            <Empty description={'No Results Found.'}/>
                // <EmptySVG style={{height: '250px'}}/>
            }

        </div>

    );
};

export default Search;