import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Avatar, Col, message, notification, Row, Typography} from 'antd';
import LoginForm from '../components/login/LoginForm';
import ForgotPasswordForm from '../components/login/ForgotPasswordForm';
import {admin} from "../tools/firebase";
import '../styles/login.less';
import {AppContext} from "../App";
import {API} from "../tools/api";
import {useMutation} from "react-query";
import {User} from "../tools/types";
import logo from '../assets/flag.jpg';
import dual_logo from '../assets/dual_logo.jpeg';


const { Title } = Typography;



const Login = () => {
    // for changing page after login
    const history = useHistory();
    const {setUser, api} = useContext(AppContext);
    // for switching between logging in and forgot pswd
    const [login, setLogin] = useState<boolean>(true);
    // getting user data
    const {
        data,
        isError,
        isLoading,
        isSuccess, reset,
        mutate: getUser, error
    } = useMutation('getUsers', api.getUsers, {retry: 2, onSuccess: (users)=> onGetUsersSuccess(users)});

    // clear session storage whenever this is loaded
    useEffect(()=>{
        sessionStorage.clear();
    }, [])

    // run once when success changes
    useEffect(()=>{
        if (isError) message.error('UnSuccessful! Please retry.').then(()=> api.logError(error));
        if (isLoading)  message.loading('Logging In...');

    }, [isSuccess, isError, isLoading])

    // when getting users data is successful
    const onGetUsersSuccess = async (users: User[]) => {
        // check if result is empty
        if (users?.length === 0) {
            message.error( 'User details not found. Please contact admin to create a profile for you.', 20)
            await api.signOut();
        } else {
            // if users is available, set current user in api
            setUser(users[0]);
            // store in session storage
            sessionStorage.setItem('user', JSON.stringify(users[0]));
            // redirect to home page
            history.push('/');
        }
    }

    // for logging in after form submit
    const handleLogin = async (values: any) => {
        try{
            const email: string = values.email.trim();

            await api.signIn(email, values.password).then(async (us)=> {
                // get user details upon login
                await getUser([{field: 'email', value: email}]);
                // go home
            });

            // setBreadcrumbs(['Home']);
        }
        catch(err) {
            message.error(err.toString(), 5);
        }
    };

    const handleForgot = async (values: any) => {
        try{
            await api.resetPassword(values.email.trim());
            message.success('Reset email sent.', 5);
        }
        catch(err) {
            message.error(err.toString(), 5);
        }
    }

    return (
        <Row className={'main'}>
            {/*Left side with form*/}
            {/*<Col xs={24} sm={24} md={12} lg={12} className={'login-form-container'} >*/}
            <Col span={24} className={'login-form-container'} >

                <Row>

                    <Col span={24}>
                        <Title className={'title'}>
                            <strong>Botswana &nbsp;&nbsp; National &nbsp;&nbsp; Front</strong>
                        </Title>
                    </Col>

                    <Col span={24}>
                        <img
                            src={dual_logo}
                            className={'dual_logo'}
                            alt={'logo'}
                        />
                    </Col>

                    <Col span={24}>
                        { login &&
                        <LoginForm handleLogin={handleLogin} setLogin={setLogin}/>
                        }

                        {!login &&
                        <ForgotPasswordForm setLogin={setLogin} handleForgot={handleForgot}/>
                        }

                    </Col>

                </Row>

            </Col>

            {/*right side with image*/}
            {/*<Col md={12} lg={12}  className={'login-image-section'}></Col>*/}
        </Row>
    );
};

export default Login;