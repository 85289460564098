import React, {useState, useEffect} from 'react';
import {Col} from "antd";
import SearchableSelect from "./SearchableSelect";
import {createSelectObjectsFromArray} from "../tools/functions";
import {Constituency, SelectOption, PropsConstituencyWardSelectors} from "../tools/types";
import {constituencies} from "../tools/constants";

const ConstituencyWardSelectors: React.FC<PropsConstituencyWardSelectors> = ({required, disabled}) => {
    // selected constituency
    const [selConstituency, setSelConstituency] = useState<SelectOption>();
    const [wards, setWards] = useState<string[]>([]);

    // runs whenever constituency changes to populate the wards
    useEffect(() => {
        const constituency: Constituency = constituencies.filter(con => con.name === selConstituency?.value)[0];
        if (constituency) setWards(constituency.wards);
        // when nothing is selected, set wards to empty
        if (!selConstituency) setWards([])
    }, [selConstituency])

    return (
        <>
            {/*constituency*/}
            <Col xs={24} sm={12} md={12} lg={12}>
                <SearchableSelect
                    name={'constituency'}
                    optionsType={'constituencies'}
                    placeholder={'Select Constituency'}
                    label={'Select Constituency:'}
                    setSelectedObject={setSelConstituency}
                    required={required}
                    disabled={disabled}
                />
            </Col>

            {/*wards*/}
            <Col xs={24} sm={12} md={12} lg={12}>
                <SearchableSelect
                    options={createSelectObjectsFromArray(wards)}
                    placeholder={'Select Ward'}
                    label={'Select Ward:'}
                    name={'ward'}
                    disabled={wards.length === 0 || disabled}
                    required={required}/>
            </Col>
        </>
    );
};

export default ConstituencyWardSelectors;