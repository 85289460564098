import React, {useContext, useEffect, useState} from 'react';
import {MassBnfApplication} from "../../tools/types";
import {institutions, NA} from "../../tools/constants";
import {useMutation} from "react-query";
import {API} from "../../tools/api";
import {error, success} from "../../components/Alerts";
import '../../styles/Register.less';

// UI
import logo_mass from '../../assets/logo-mass.png';
import logo_udc from '../../assets/logo-udc.png';
import flag from '../../assets/flag.jpg';
import {Col, Divider, Form, Input, Row, DatePicker, Space, Select, Checkbox, Button, message} from "antd";
import {SendOutlined, FacebookOutlined, InstagramOutlined, TwitterOutlined} from "@ant-design/icons";
import SearchableSelect from "../../components/SearchableSelect";
import {AppContext} from "../../App";
import SchoolSelector from "../../components/SchoolSelector";

const {Option} = Select;

const ApplyMassBnf = () => {
    const {api} = useContext(AppContext);
    const [form] = Form.useForm();
    const {
        isSuccess,
        isError,
        isLoading,
        reset,
        error,
        mutate: addApplicationM
    } = useMutation('addMassBnfApplication', api.addMassBnfApplication, {retry: 2});
    // store fname and sname as they are being typed
    const [fname, setFname] = useState<string>("");
    const [sname, setSname] = useState<string>("");

    // run once when success changes
    useEffect(() => {
        if (isSuccess) message.success("Application Successfully Submitted.").then(() => {
            // clear the form
            form.resetFields();
            // reset the query
            reset();
        });
        if (isError) message.error('UnSuccessful! Please retry.').then(() => api.logError(error));
        if (isLoading) message.info('Loading...');

    }, [isSuccess, isError, isLoading])


    // method to register from form details
    const register = (v: any) => {

        // fill in details
        let application: MassBnfApplication = {
            appliedOn: new Date(),
            school: v.school  || NA,
            dob: v['dob'].toDate(),
            docId: "",
            email: v.email  || NA,
            facebook: v.facebook ? v.facebook : "",
            fname: v.fname,
            gender: v.gender,
            instagram: v.instagram ? v.instagram : "",
            omang: v.omang,
            phone: "+267"+v.phone.trim(),
            program: v.program || NA,
            sname: v.sname,
            status: 'pending',
            studentId: v.studentId || NA,
            twitter: v.twitter ? v.twitter : "",
            level: v.level || NA,
            evaluations: [],
            evalStage: 'n/a',
            // number set in the backend
            membershipNumber: 0,
        }

        // add to database
        addApplicationM(application);
    }

    return (
        <div className={'register'}>
            <Row gutter={[4, 16]} justify="space-between" align={'middle'}>

                {/*logo -- show on !mobile screens */}
                <Col xs={24} sm={6} md={6} lg={6}>
                    <img
                        src={logo_mass}
                        className={'img-logo'}
                    />
                </Col>

                {/*Form Heading -- show on all screens*/}
                <Col xs={24} sm={16} md={12} lg={12}>
                    <h1 className={'main-heading'}>
                        MASS-BNF MEMBERSHIP
                        APPLICATION FORM
                    </h1>
                </Col>

                {/*logo !mobile screens*/}
                <Col xs={0} sm={0} md={6} lg={6}>
                    <img
                        src={logo_udc}
                        className={'img-logo'}
                    />
                </Col>


                {/*About MASS_BNF*/}
                <Divider orientation="left" className={'divider'}><strong>ABOUT MASS-BNF</strong> </Divider>
                <Col span={24}>
                    <div className={'about-text'}>
                        {/*<h2><strong>ABOUT MASS-BNF</strong></h2>*/}
                        <p><strong>
                            Movement Against Student Suppression (MASS-BNF) is a student auxiliary body of the Botswana
                            National Front with a revolutionary mandate to mobilize students and young people from
                            tertiary institutions. MASS-BNF is a center mouth for social justice and equitable society.
                            <br/>
                            <br/>
                            MASS-BNF is an affiliate partner of the student coalition project named Moono Wa Baithuti
                            which houses other progressive forces to deliver decent lives for all.
                        </strong></p>
                    </div>
                </Col>

                {/*<Col span={24}> <hr/> </Col>*/}

                {/*Form details*/}
                <Col span={24} className={'form-wrapper'}>
                    <img
                        className="form-bg"
                        src={flag}
                        alt="flag"
                    />

                    <Form
                        form={form}
                        onFinish={register}
                        layout={'vertical'}
                        className={'form-content'}
                    >

                        <Divider orientation="left" className={'divider'}><strong>PERSONAL DETAILS</strong> </Divider>

                        <Row gutter={[8, 16]}>

                            {/*Surname*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'sname'} label={'Surname'}
                                           rules={[{required: true, message: 'Please enter your Surname.'}]}>
                                    <Input placeholder={'Surname'} type={'text'}
                                           onChange={(e) => setSname(e.target.value)}/>
                                </Form.Item>
                            </Col>

                            {/*FirstName*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'fname'} label={'First Name(s)'}
                                           rules={[{required: true, message: 'Please enter your First Name(s).'}]}>
                                    <Input placeholder={'First Name(s)'} type={'text'}
                                           onChange={(e) => setFname(e.target.value)}/>
                                </Form.Item>
                            </Col>

                            {/*DOB*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'dob'} label={'Date of Birth (YYYY-MM-DD)'}
                                           rules={[{required: true, message: 'Please select your Date of Birth.'}]}>
                                    <DatePicker style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>

                            {/*Gender*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'gender'} label={'Gender'}
                                           rules={[{required: true, message: 'Please select your Gender.'}]}>
                                    <Select placeholder="Select Gender">
                                        <Option value="F">Female</Option>
                                        <Option value="M">Male</Option>
                                    </Select>

                                </Form.Item>
                            </Col>

                            {/*Omang*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'omang'} label={'Omang / Passport Number'} rules={[{
                                    required: true,
                                    message: 'Please enter your Omang / Passport Number.'
                                }]}>
                                    <Input placeholder={'012345678'} type={'text'}/>
                                </Form.Item>
                            </Col>

                            {/*phone*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'phone'} label={'Mobile Phone Number'} rules={[{
                                    required: true,
                                    message: 'Please enter your Mobile Phone Number.'
                                }]}>
                                    <Input addonBefore="+267" placeholder={'74123456'} type={'number'}/>
                                </Form.Item>
                            </Col>

                            {/*select university and campus*/}
                            <SchoolSelector required={false} />

                            {/*program*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'program'} label={'Program of Study'}
                                           // rules={[{required: true, message: 'Please enter your Programme of Study.'}]}
                                >
                                    <Input placeholder={'BSc Computer Science'} type={'text'}/>
                                </Form.Item>
                            </Col>

                            {/*studentId*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'studentId'} label={'Student ID Number'}
                                           //rules={[{required: true, message: 'Please enter your Student ID Number.'}]}
                                >
                                    <Input placeholder={'12345678'} type={'text'}/>
                                </Form.Item>
                            </Col>

                            {/*email*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'email'} label={'Email Address'}
                                           rules={[
                                               {type: 'email', message: 'The input is not a valid email address.'},
                                               //{required: true, message: 'Please enter your Email Address.'}
                                           ]
                                           }
                                >
                                    <Input placeholder={'abc@gmail.com'} type={'email'}/>
                                </Form.Item>
                            </Col>

                            {/*instagram*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'instagram'} label={'Instagram'}>
                                    <Input defaultValue={'@'} type={'text'}/>
                                </Form.Item>
                            </Col>

                            {/*twitter*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'twitter'} label={'Twitter'}>
                                    <Input defaultValue={'@'} type={'text'}/>
                                </Form.Item>
                            </Col>

                            {/*facebook*/}
                            <Col xs={24} sm={12} md={12} lg={12}>
                                <Form.Item name={'facebook'} label={'Facebook'}>
                                    <Input placeholder={'Facebook'} type={'text'}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider orientation="left" className={'divider'}><strong>MEMBERSHIP OATH
                            DECLARATION</strong></Divider>

                        <p><strong>
                            I, <i className={'name-text'}><u>{sname.toUpperCase()} {fname.toUpperCase()}</u></i>, pledge
                            myself as a member of Movement Against Student Suppression (MASS-BNF). I solemnly declare
                            that I will abide by the aims, objectives and radical policies of MASS-BNF as set out in the
                            constitution of MASS-BNF.
                            <br/>
                            <br/>
                            I Further commit to abide by the principle of democratic centralism which is that the
                            individual is subordinate to the organization, the minority is the subordinate to the
                            majority , the lower level is subordinate to the higher level, and decision of the upper
                            structures are binding on the lower structure.
                            <br/>
                        </strong></p>

                        {/*Agreement to the oath*/}
                        <Form.Item name={'oath'} rules={[{
                            required: true,
                            message: 'Please tick here if you agree to the oath above.'
                        }]}>
                            <Checkbox.Group>
                                <Checkbox value={true} className={'checkbox'}> <strong>I AGREE </strong> </Checkbox>
                            </Checkbox.Group>
                        </Form.Item>

                        {/*submit button*/}
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className={'btn-submit'} loading={isLoading}>
                                <Space size={'middle'}>
                                    <strong>SUBMIT APPLICATION</strong>
                                    <SendOutlined/>
                                </Space>
                            </Button>
                        </Form.Item>

                    </Form>

                </Col>

                {/*Social media icons*/}
                <Col span={24}>
                    <Space size={'middle'} align="start" style={{marginTop: '10px', marginBottom: '0px'}}>
                        <InstagramOutlined/>
                        <FacebookOutlined/>
                        <TwitterOutlined/>
                        <p><strong>MassBnf</strong></p>
                    </Space>
                </Col>

                {/*bottom design*/}
                <Col span={18}>
                    <div className={'bottom left'}></div>
                </Col>
                <Col span={6}>
                    <div className={'bottom right'}></div>
                </Col>

            </Row>
        </div>
    );
};

export default ApplyMassBnf;