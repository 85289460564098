import {db, admin, addToArray} from './firebase';
import firebase from "firebase";
import {
    BnfApplication,
    MassBnfApplication,
    CollectionReference,
    User,
    Filter,
    ApplicationStatus,
    EvaluatorType, Evaluation, EvaluationStage
} from "./types";
import {message} from "antd";
import {deletedToString, getApplicationType} from "./functions";
import {blankUser} from "./constants";

// class for API functions
export class API {
    // current user
    public user: User = blankUser;
    public massBnfApplicationsRef: CollectionReference = db.collection('massBnfApplications');
    public bnfApplicationsRef: CollectionReference = db.collection('bnfApplications');
    public usersRef: CollectionReference = db.collection('users');
    public debugLogRef: CollectionReference = db.collection('debugLog');

    // ----------- APPLICATIONS --------
    // adding a mass-bnf application to the database
    public addMassBnfApplication = async (application: MassBnfApplication): Promise<void> => {
        // get a unique doc started
        let newApplicationRef = await this.massBnfApplicationsRef.doc();
        // set that doc's details
        await newApplicationRef.set({...application, docId: newApplicationRef.id});
    }

    // adding a mass application to the database
    public addBnfApplication = async (application: BnfApplication): Promise<void> => {
        // get a unique doc started
        let newApplicationRef = await this.bnfApplicationsRef.doc();
        // set that doc's details
        await newApplicationRef.set({...application, docId: newApplicationRef.id});
    }

    // function to get applications
    public getApplications = async (data: {filters: Filter[], type: EvaluatorType}): Promise<BnfApplication[] | MassBnfApplication[]> => {
        // select doc ref depending on type supplied
        let docsRef = (data.type === 'bnf')? this.bnfApplicationsRef : this.massBnfApplicationsRef;

        // chain the query field in
        data.filters.forEach(filter => {
            docsRef = this.chain(docsRef, filter);
        })

        // array to store docs of appropriate type
        let applications: BnfApplication[] | MassBnfApplication[] = [];

        try{
            // get the documents
            const docs = await docsRef.orderBy('appliedOn', 'asc').get();

            // prepare
            docs.forEach(doc => {
                const d = doc.data();
                // @ts-ignore fields overwritten
                applications.push({
                    ...d,
                    dob: d.dob.toDate(),
                    appliedOn: d.appliedOn.toDate(),
                    evaluations: this.processEvaluations(d.evaluations),
                })

            })
        }
        catch(err){
            await this.logError(err);
            message.error('This query requires an index. Please alert the admin.', 10);
        }

        return applications;
    }

    // evaluation of an application with denied or approved
    public evaluate = async (data: {app: MassBnfApplication | BnfApplication , user: User, status: ApplicationStatus, comment: string}) => {
        // determine type of application -- mass-bnf has university field, but bnf does not
        let type: EvaluatorType = getApplicationType(data.app);

        // select collection
        let docRef = (type === 'bnf')? this.bnfApplicationsRef : this.massBnfApplicationsRef;

        // new status and evaluation stage
        let newStatus: ApplicationStatus = data.status;
        let newEvalStage: EvaluationStage = data.app.evalStage;

        // for bnf applications, final status depends on level
        if(type==='bnf'){
            // move evalStage level forward if approved
            if(data.status === 'approved' && data.app.evalStage !== 'secgen'){
                if(data.app.evalStage === 'ward') newEvalStage = 'constituency';
                if(data.app.evalStage === 'constituency') newEvalStage = 'secgen';
                // set the status to pending again
                newStatus = 'pending';
            }
        }

        // create an evaluation object
        const evaluation: Evaluation = {
            comment: data.comment || "",
            contactDetails: data.user.phone + " / " + data.user.email,
            evalStage: data.app.evalStage ,
            evaluatedBy: data.user.sname + ", " + data.user.fname,
            evaluatedOn: new Date(),
            ward: data.user.ward,
            constituency: data.user.constituency,
            status: data.status
        }

        // select doc
        await docRef.doc(data.app.docId).update({
            // new status
            status: newStatus,
            // new evaluation stage
            evalStage: newEvalStage,
            // add to evaluation details array
            evaluations: addToArray(evaluation)
        })
    }

    // -------- USERS ------

    // for getting user's details -- can get all if no parameters are supplied
    public getUsers = async (filters: Filter[]): Promise<User[]> => {
        // stores
        let docs;
        // stores array of users from the db
        let users: User[] = [];

        // initial docRef
        let docsRef = this.usersRef;

        // loop through filters and chain
        filters.forEach(filter => {
            docsRef = this.chain(docsRef, filter);
        })

        try{
            // get using chained docsRef
            docs = await docsRef.get();

            // loop through docs from db and fill array
            docs.forEach(doc => {
                const data = doc.data();

                // parse each user and add to array
                users.push({
                    gender: data.gender,
                    docId: data.docId,
                    email: data.email,
                    fname: data.fname,
                    omang: data.omang,
                    phone: data.phone,
                    role: data.role,
                    evalStage: data.evalStage,
                    sname: data.sname,
                    constituency: data.constituency,
                    evaluatorType: data.evaluatorType,
                    position: data.position,
                    uid: data.uid,
                    ward: data.ward,
                    deleted: deletedToString(data.deleted)
                })
            });
        }
        catch(err){
            await this.logError(err);
            message.error('This query requires an index. Please alert the admin.', 10);
        }

        // return the result
        return users;

    }

    // adding a user to the database
    public addUser = async (user: User): Promise<void> => {
        // create login details
        await admin.auth().createUserWithEmailAndPassword(user.email.trim(), 'password')
            .then(async (userCredential) => {
                const uid: string = await userCredential.user?.uid as string;
                // create user in database
                // get a unique doc started -- use the firebase uid as the docId
                const newUserRef = this.usersRef.doc(uid);
                // set that doc's details
                await newUserRef.set({...user, docId: newUserRef.id, uid: uid ? uid : ''})
            })
    }

    // edit a user on the database
    public editUser = async (user: User): Promise<void> => {
        await this.usersRef.doc(user.docId).update(user);
    }

    // "delete" a user from the database -- actually only sets deleted field to true
    public deleteUser = async (user: User): Promise<void> => {
        // revoke their access to the database

        // mark their doc as deleted
        await this.usersRef.doc(user.docId).update({
            deleted: true
        });

    }

    // ---------- OTHER ---------------

    public signIn = async (email: string, password: string) => {
        // await admin.auth().signInWithEmailAndPassword(email, password);
        // errors logged in caller

        return admin.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(() => {
                // Existing and future Auth states are now persisted in the current
                // session only. Closing the window would clear any existing state even
                // if a user forgets to sign out.
                // ...
                // New sign-in will be persisted with session persistence.
                return firebase.auth().signInWithEmailAndPassword(email, password);
            })
    }

    // for resetting a password
    public resetPassword = async (email: string) => {
        return admin.auth().sendPasswordResetEmail(email);
    }

    // for logging out
    public signOut = async () => {
        return admin.auth().signOut().then(()=> sessionStorage.clear());
    }

    // function to chain a query
    private chain = (docsRef: any, filter: Filter) => {
        return docsRef.where(filter.field, '==', filter.value);
    }

    // preprocess evaluations from firestore data
    public processEvaluations = (evaluations: any[]) => {
        // list of evaluations
        let evals: any[] = [];

        // loop through to fix dates
        evaluations.forEach(evall => evals.push({
                ...evall,
                evaluatedOn: evall.evaluatedOn ? evall.evaluatedOn.toDate() : undefined
            })
        );

        // sort
        evals.sort((a: any, b: any) => {
            if(a.evaluatedOn > b.evaluatedOn) return 1;
            else if(a.evaluatedOn < b.evaluatedOn) return -1;
            else return 0;
        });

        return evals;
    }

    // Log any errors onto the database
    public logError = async (error: any) => {
        try {
            await this.debugLogRef.add({
                date: new Date(),
                message: error.message,
                details: error.stack
            });
        } catch (err) {
            // irony of issues with the logger itself -- refresh the app
            message.error('Error occurred, refreshing the page.').then(() => window.location.reload());
        }
    }
}