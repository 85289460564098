import React, {useEffect, useState} from 'react';
import {Col, Form, Input, Row} from "antd";
import SearchableSelect from "./SearchableSelect";
import {constituencies, institutions} from "../tools/constants";
import {Constituency, PropsConstituencyWardSelectors, SelectOption, Institution} from "../tools/types";
import {createSelectObjectsFromArray} from "../tools/functions";

const SchoolSelector: React.FC<PropsConstituencyWardSelectors> = ({required, disabled}) => {
    // selected university
    const [selInst, setSelInst] = useState<SelectOption>();
    const [schools, setSchool] = useState<string[]>([]);

    // runs whenever university changes to populate the names
    useEffect(() => {
        const institution: Institution = institutions.filter(uni => uni.level === selInst?.value)[0];
        if (institution) setSchool(institution.names);
        // when nothing is selected, set wards to empty
        if (!selInst) setSchool([])
    }, [selInst])

    return (
        <>
            {/*level of study*/}
            <Col xs={24} sm={12} md={12} lg={12}>
                <SearchableSelect
                  placeholder={'Select Level of Study'}
                  label={'Level:'}
                  name={'level'}
                  optionsType={'universities'}
                  setSelectedObject={setSelInst}
                  required={required}
                  disabled={disabled}
                />
            </Col>

            {/*school*/}
            <Col xs={24} sm={12} md={12} lg={12}>
                <SearchableSelect
                    options={createSelectObjectsFromArray(schools)}
                    placeholder={'Select School'}
                    label={'School:'}
                    name={'school'}
                    disabled={schools.length === 0 || disabled}
                    required={required}/>
            </Col>
        </>
    );
};

export default SchoolSelector;